import { throttle } from 'lodash-es';
import { formatWei } from '@/utils/filters';

export default function gas() {
  return {
    namespaced: true,
    state: () => ({
      gasData: null,
      ethPrice: null,
    }),
    actions: {
      init({ dispatch }) {
        dispatch('connectPriceFeed');
        dispatch('connectGasPrice');
      },
      connectPriceFeed({ commit }) {
        const socket = new WebSocket('wss://ws.kraken.com');

        socket.onopen = () => {
          socket.send(JSON.stringify({
            event: 'subscribe',
            subscription: {
              name: 'ticker',
            },
            pair: ['ETH/USDC'],
          }));
        };

        const debounced = throttle((data) => {
          commit('setEthPrice', data.c[0]);
        }, 5000);
        socket.onmessage = (response) => {
          if (!response.data) {
            return;
          }
          const result = JSON.parse(response.data);
          if (result[2] !== 'ticker') {
            return;
          }

          debounced(result[1]);
        };
      },
      connectGasPrice({ commit }) {
        const socket = new WebSocket('wss://gas.compass.art/prices');

        socket.onmessage = ({ data }) => {
          commit('setGasData', JSON.parse(data).data);
        };
      },
    },
    mutations: {
      setEthPrice(state, ethPrice) {
        state.ethPrice = ethPrice;
      },
      setGasData(state, gasData) {
        state.gasData = gasData;
      },
    },
    getters: {
      gasData(state) {
        return state.gasData;
      },
      ethPrice(state) {
        return state.ethPrice;
      },
      tradeCost(state) {
        if (!state.gasData || !state.ethPrice) {
          return null;
        }

        return (
          formatWei(state.gasData?.fast)
          * state.ethPrice
          * 310000
        );
      },
      gasPriceInGwei(state) {
        if (!state.gasData) {
          return null;
        }
        return formatWei(state.gasData?.fast, 'Gwei');
      },
      presetGasPriceInGwei(state) {
        return (preset) => {
          if (!state.gasData) {
            return null;
          }
          return formatWei(state.gasData?.[preset], 'Gwei');
        };
      },
      eip1559GasPriceInGwei(state) {
        return (preset) => {
          if (!state.gasData) {
            return null;
          }
          return {
            fee: formatWei(state.gasData?.eip1559?.[preset].maxFeePerGas, 'Gwei'),
            priorityFee: formatWei(state.gasData?.eip1559?.[preset].maxPriorityFeePerGas, 'Gwei'),
          };
        };
      },
    },
  };
}
