import { inject, ref } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';
import Web3 from 'web3';
import { useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { async } from 'regenerator-runtime';

const authPrefix = process.env.VUE_APP_AUTH_PREFIX ?? 'http://localhost:3002/auth';

export default function useAuth() {
  const notify = inject('notify');
  const store = useStore();
  const isSigningIn = ref(false);
  const windowObjectReference = ref(null);

  const onBeforeLogin = async () => {
    await store.dispatch('auth/logout');
  };

  const signInWithEmail = async (destination) => {
    isSigningIn.value = true;
    await onBeforeLogin();
    return axios.post(`${authPrefix}/magic`, {
      destination,
    }).finally(() => {
      isSigningIn.value = false;
    });
  };

  const signInWithWallet = async () => {
    isSigningIn.value = true;
    // we fetch a provider from the web3 modal
    // and pass it to the store method
    await onBeforeLogin();
    const provider = await store.dispatch('auth/getProvider');
    const web3 = new Web3(provider);

    const [address] = await web3.eth.getAccounts().catch(() => []);
    const { data: { message } } = await axios.get(`${authPrefix}/web3`, {
      params: {
        address,
      },
    });

    const dataToSign = web3.utils.fromUtf8(message);
    const signature = await web3.eth.personal.sign(dataToSign, address);

    await axios.post(`${authPrefix}/web3/callback`, {
      address,
      signature,
    }, {
      withCredentials: true,
    });
  };

  // eslint-disable-next-line no-async-promise-executor
  const openSignInWindow = (url, name) => new Promise(async (resolve, reject) => {
    isSigningIn.value = true;
    await onBeforeLogin();
    const receiveMessage = (event) => {
      if (event.data.type !== 'authCallback') {
        return;
      }
      isSigningIn.value = false;
      if (event.data.result === 'success') {
        resolve();
      } else {
        reject(new Error('Failed to sign in'));
      }
    };

    window.removeEventListener('message', receiveMessage);

    // window features
    const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

    if (windowObjectReference.value === null || windowObjectReference.value.closed) {
      /* if the pointer to the window object in memory does not exist
        or if such pointer exists but the window was closed */
      windowObjectReference.value = window.open(url, name, strWindowFeatures);
    } else {
      /* else the window reference must exist and the window
        is not closed; therefore, we can bring it back on top of any other
        window with the focus() method. There would be no need to re-create
        the window or to reload the referenced resource. */
      windowObjectReference.value.focus();
    }

    // add the listener for receiving a message from the popup
    window.addEventListener('message', (event) => receiveMessage(event), false);
  });

  const { mutate: deleteAuthMethodMutation } = useMutation(gql`
    mutation DeleteAuthMethod($id: Int!) {
      deleteAuthMethod(id: $id)
    }
  `, {
    errorPolicy: 'none',
  });

  const deleteAuthMethod = async (id) => {
    await deleteAuthMethodMutation({
      id,
    });

    await store.dispatch('auth/getUser');
  };

  return {
    isSigningIn,
    signInWithEmail,
    signInWithWallet,
    openSignInWindow,
    signInWithGoogle: () => openSignInWindow(`${authPrefix}/google`, 'Sign in with Google'),
    signInWithDiscord: () => openSignInWindow(`${authPrefix}/discord`, 'Sign in with Google'),
    deleteAuthMethod,
  };
}
