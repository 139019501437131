import eventBus from '@/utils/event-bus';
import { onScopeDispose } from 'vue';

export function mapGraphqlErrors(graphQLErrors) {
  const errorMap = new Map();

  graphQLErrors.forEach((item) => {
    errorMap.set(item.extensions.exception.path.join('.'), {
      code: item.extensions.code,
      errorFields: item.extensions.errorFields,
    });
  });

  return errorMap;
}

export function useApolloErrors() {
  const onApolloError = (error) => {
    eventBus.$emit('apollo:error', error);
  };

  let subscriptionCb = null;

  const unsubscribe = () => {
    if (subscriptionCb) {
      eventBus.$off(subscriptionCb);
      subscriptionCb = null;
    }
  };

  const subscribe = (cb, codes = []) => {
    unsubscribe();
    subscriptionCb = (error) => {
      if (!error.extensions || codes.indexOf(error.extensions.code) === -1) {
        return;
      }

      cb(error);
    };
    eventBus.$on('apollo:error', subscriptionCb);
  };

  onScopeDispose(() => {
    unsubscribe();
  });

  return {
    onApolloError,
    subscribe,
    unsubscribe,
  };
}
