<template>
  <div>
    <Metainfo>
      <template #title="{ content }">
        {{ content ? `${content} - Compass` : `Compass` }}
      </template>
    </Metainfo>

    <ReloadNotification
      class="w-full"
    />

    <AppShell>
      <RouterView v-if="isLoggedIn" />

      <GuestHero
        v-else
        title="Sign in to Compass"
        description="To access this page, please sign in or create a Compass account."
        :is-loading="!isReady"
      />
    </AppShell>

    <UiDialogLegacy />

    <ConnectWalletModal
      class="z-50"
    />

    <UiModal
      v-model="isPremiumModalVisible"
      style-classes="bg-black bg-noise rounded-2xl shadow-lg"
      size="xl"
      z-index="100"
      bg-blur
    >
      <PremiumModal
        ref="premiumModal"
        :close="() => isPremiumModalVisible = false"
      />
    </UiModal>

    <RateLimitErrorHandler />

    <UiModal
      v-model="isTokenModalVisible"
      align="top"
      size="7xl"
      style-classes="bg-white dark:bg-gray-850 dark:text-gray-100 rounded-2xl relative"
    >
      <TokenModal
        :ref="(el) => tokenModal = el"
        :collection="tokenModalCollection"
        :token-id="tokenModalTokenId"
      />
    </UiModal>

    <UiDialog />

    <img
      src="./assets/pass-pattern.png"
      width="0"
      height="0"
    >
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  provide,
  ref,
  nextTick,
} from 'vue';
import { useQuery } from '@vue/apollo-composable';
import blockNumberQuery from '@/graphql/meta/queries/blockNumber.query.gql';
import blockHeadSubscription from '@/graphql/meta/subscriptions/blockHead.subscription.gql';
import eventBus from '@/utils/event-bus';
import routesNames from '@/router/routesNames';

import UiModal from '@/components/ui/UiModal.vue';
import AppShell from '@/components/AppShell2.vue';
import UiDialogLegacy from '@/components/ui-dialog/UiDialog.vue';
import UiDialog from '@/components/ui/UiDialog.vue';
import ConnectWalletModal from '@/components/ConnectWalletModal.vue';
import ReloadNotification from '@/components/ReloadNotification.vue';
import PremiumModal from './components/PremiumModal.vue';
import RateLimitErrorHandler from './components/RateLimitErrorHandler.vue';
import useTokenModal from './composition/tokens/useTokenModal';
import TokenModal from './components/TokenModal.vue';
import useDialogs from './components/ui/composition/use-dialogs';
import GuestHero from './components/GuestHero.vue';

export default defineComponent({
  components: {
    UiModal,
    ConnectWalletModal,
    UiDialog,
    UiDialogLegacy,
    AppShell,
    PremiumModal,
    RateLimitErrorHandler,
    ReloadNotification,
    TokenModal,
    GuestHero,
  },

  metaInfo() {
    return {
      title: 'Compass',
      htmlAttrs: {
        lang: 'en',
        amp: false,
      },
    };
  },

  setup() {
    const { notify, open } = useDialogs();
    const {
      result: blockHeadResult,
      subscribeToMore,
    } = useQuery(blockNumberQuery);
    const isPremiumModalVisible = ref(false);
    const premiumModal = ref(null);
    function showPremiumModal(planId = null, action = null) {
      if (isPremiumModalVisible.value) {
        return;
      }
      isPremiumModalVisible.value = true;
      nextTick(() => {
        premiumModal.value.show(action);
      });
    }

    const blockHead = computed(() => blockHeadResult.value?.meta.eth.lastBlockSynced);

    provide('eth:blockHead', blockHead);

    provide('notify', notify);

    provide('confirm', async (message, options = {}) => {
      const { confirmed } = open({
        message,
        ...options,
      });

      const response = await confirmed();

      if (!response) {
        throw new Error('User canceled');
      }

      return response;
    });

    subscribeToMore({
      document: blockHeadSubscription,
      updateQuery(previousResult, { subscriptionData }) {
        if (!previousResult || !previousResult?.meta) {
          return {
            meta: {
              eth: {
                lastBlockSynced: subscriptionData.data.blockHead,
              },
            },
          };
        }
        const data = JSON.parse(JSON.stringify(previousResult));
        data.meta.eth.lastBlockSynced = subscriptionData.data.blockHead;
        return data;
      },
    });

    const {
      isVisible: isTokenModalVisible,
      tokenModal,
      collection: tokenModalCollection,
      tokenId: tokenModalTokenId,
    } = useTokenModal();

    return {
      routesNames,
      showPremiumModal,
      isPremiumModalVisible,
      premiumModal,
      isTokenModalVisible,
      tokenModal,
      tokenModalCollection,
      tokenModalTokenId,
    };
  },

  data() {
    return {
      longWaitTimeout: null,
      loadingMessage: null,
      showStatusPage: false,
    };
  },
  computed: {
    isLoggedIn() {
      return this.$store.getters['auth/isLoggedIn'];
    },
    isReady() {
      return this.$store.getters['auth/isReady'];
    },
  },
  mounted() {
    const shouldInitCheckout = new URL(window.location.href).searchParams.get('init_checkout');
    if (shouldInitCheckout) {
      this.showPremiumModal(shouldInitCheckout);
    }
    eventBus.$on('auth:showPremiumModal', (action) => {
      if (this.$store.getters['auth/isPremium']) {
        return;
      }

      const showModal = () => {
        this.showPremiumModal(null, action);
      };
      if (!this.$store.getters['auth/isLoggedIn']) {
        eventBus.$emit('auth:signIn', action);
        eventBus.$on('auth:didSignIn', () => {
          if (this.$store.getters['auth/isPremium']) {
            return;
          }

          showModal();
        });
        return;
      }

      showModal();
    });
  },
});
</script>
