import eventBus from '@/utils/event-bus';
import { computed } from 'vue';
import { useStore } from 'vuex';

export function useUserStore() {
  const store = useStore();

  const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

  const userWallets = computed(() => store.getters['auth/wallets']);

  const automationCreditBalance = computed(() => store.getters['auth/automationCreditBalance']);

  const onLogin = (callback) => {
    eventBus.$on('auth:didSignIn', callback);
  };

  return {
    isLoggedIn,
    userWallets,
    onLogin,
    automationCreditBalance,
  };
}
