import { WebSocketLink } from '@apollo/client/link/ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';

export default function createWsLink({ wsEndpoint }) {
  const wsClient = new SubscriptionClient(wsEndpoint, {
    reconnect: true,
  });

  const wsLink = new WebSocketLink(wsClient);

  return wsLink;
}
