export default {
  methods: {
    getDecodedFilters(filters) {
      return JSON.parse(atob(filters));
    },
    getEncodedFilters(filters) {
      return btoa(JSON.stringify(filters));
    },
  },
};
