import gql from 'graphql-tag';
import { COLLECTION_LIST_ITEM_FIELDS } from './collectionListItemFields.fragment';

export const COLLECTION_WITH_STATS_FIELDS = gql`
  fragment CollectionWithStats_Fields on CollectionWithStats {
    id
      collection {
        ...CollectionListItem_Fields
        listedFloor
        topOffer
        previousListedFloor
        contractCreatedTimestamp
        charts(period: "1m", size: $period) {
          volume
        }
        totalProfits(period: $period)
      }
      volume
      salesCount
      bidsFilledCount
      avgPrice
      maxPrice
      medianPrice
      previous {
        volume
        salesCount
        bidsFilledCount
        avgPrice
        minPrice
        maxPrice
        medianPrice
      }
  }
  ${COLLECTION_LIST_ITEM_FIELDS}
`;
