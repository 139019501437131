import { computed } from 'vue';
import { useStore } from 'vuex';
import eventBus from '@/utils/event-bus';

export function usePremium() {
  const store = useStore();
  const isPremium = computed(() => store.getters['auth/isPremium']);
  const isLoggedIn = computed(() => store.getters['auth/isLoggedIn']);

  function requirePremium(action = null) {
    console.log('requirePremium', isLoggedIn.value, isPremium.value);
    if (isPremium.value) {
      return;
    }

    eventBus.$emit('auth:showPremiumModal', action);
    throw new Error('Premium access required');
  }

  function requireAuth() {
    if (isLoggedIn.value) {
      return Promise.resolve();
    }

    eventBus.$emit('auth:signIn');
    return new Promise((resolve) => {
      eventBus.$on('auth:didSignIn', resolve);
    });
  }

  return {
    isPremium,
    requirePremium,
    requireAuth,
  };
}
