import { WALLET_FIELDS } from '@/graphql/wallet/fragments/walletFields.fragment';
import gql from 'graphql-tag';

export const TOKEN_FIELDS = gql`
  fragment Token_Fields on Token {
      id
      tokenId
      tokenUri
      image
      previewImageUrl
      name
      owner {
        ...Wallet_Fields
      }
      rarityScore
      rarityRank
      attributes {
          attribute {
            name
          }
          attributeValue {
            value
            tokenCount
          }
      }
  }
  ${WALLET_FIELDS}
`;
