import gql from 'graphql-tag';

export const PUBLIC_TEMPLATE_FIELDS_FRAGMENT = gql`
  fragment PublicTemplateFields on PublicTemplate {
    id
      name      
      shortDescription
      imageUrl
      createdAt
      starCount
      isStarred
  }
  `;
