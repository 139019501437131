import gql from 'graphql-tag';

export const WALLET_FIELDS = gql`
  fragment Wallet_Fields on Wallet {
    id
    name
    openseaProfile
    displayName
  }
`;
