import { createRouter, createWebHistory } from 'vue-router';
import { isEqual } from 'lodash-es';
import { lazyLoadRetry } from '@/router/lazy-load-retry';

import routesNames from './routesNames';

const WalletPortfolioOverviewVue = () => lazyLoadRetry(() => import(/* webpackChunkName: "wallet-portfolio" */ '@/components/wallets/WalletPortfolioOverview.vue'));
const WalletPortfolioTokensVue = () => lazyLoadRetry(() => import(/* webpackChunkName: "wallet-portfolio" */ '@/components/wallets/WalletPortfolioTokens.vue'));

const Collections = () => lazyLoadRetry(() => import(/* webpackChunkName: "collections" */ '@/views/collections/Collections.vue'));

const Trending = () => lazyLoadRetry(() => import(/* webpackChunkName: "collections" */ '@/views/leaderboards/Trending.vue'));

const MintingCollections = () => lazyLoadRetry(() => import(/* webpackChunkName: "minting-collections" */ '@/views/collections/MintingCollections.vue'));

const OwnedCollections = () => lazyLoadRetry(() => import(/* webpackChunkName: "owned-collections" */ '@/views/collections/OwnedCollections.vue'));

const Bluechips = () => lazyLoadRetry(() => import(/* webpackChunkName: "top-collections" */ '@/views/leaderboards/Bluechips.vue'));

const Pulse = () => lazyLoadRetry(() => import(/* webpackChunkName: "pulse" */ '@/views/Pulse.vue'));
const PulseOverview = () => lazyLoadRetry(() => import(/* webpackChunkName: "pulse" */ '@/views/PulseOverview.vue'));
const PulseProfitLeaderboard = () => lazyLoadRetry(() => import(/* webpackChunkName: "pulse" */ '@/views/PulseProfitLeaderboard.vue'));

const Crates = () => lazyLoadRetry(() => import(/* webpackChunkName: "crates" */ '@/views/Crates.vue'));

const Alerts = () => lazyLoadRetry(() => import(/* webpackChunkName: "alerts" */ '@/views/Alerts.vue'));

const SingleCollection = () => lazyLoadRetry(() => import(/* webpackChunkName: "single-collections" */ '@/views/collections/SingleCollection.vue'));
const Trade = () => lazyLoadRetry(() => import(/* webpackChunkName: "trade" */ '@/views/collections/Trade.vue'));

const CollectionChartsWrapper = () => lazyLoadRetry(() => import(/* webpackChunkName: "single-collections" */ '@/components/collections/CollectionChartsWrapper.vue'));

const CollectionMinting = () => lazyLoadRetry(() => import(/* webpackChunkName: "collection-minting" */ '@/components/collections/CollectionMinting.vue'));

const CollectionHolders = () => lazyLoadRetry(() => import(/* webpackChunkName: "collection-holders" */ '@/components/collections/CollectionHolders.vue'));

const CollectionTraits = () => lazyLoadRetry(() => import(/* webpackChunkName: "collection-traits" */ '@/components/collections/CollectionTraits.vue'));

const CollectionItems = () => lazyLoadRetry(() => import(/* webpackChunkName: "collection-items" */ '@/components/collections/CollectionItems.vue'));

const CollectionTransactions = () => lazyLoadRetry(() => import(/* webpackChunkName: "collection-transactions" */ '@/components/collections/CollectionActivity.vue'));
const CollectionProfitLeaderboard = () => lazyLoadRetry(() => import(/* webpackChunkName: "collection-leaderboard" */ '@/components/collections/CollectionProfitLeaderboard.vue'));

const SingleWallet = () => lazyLoadRetry(() => import(/* webpackChunkName: "single-wallet" */ '@/views/wallet/SingleWallet.vue'));

const WalletAnalytics = () => lazyLoadRetry(() => import(/* webpackChunkName: "single-wallet" */ '@/components/wallets/WalletAnalytics.vue'));

const WalletTransactions = () => lazyLoadRetry(() => import(/* webpackChunkName: "wallet-transactions" */ '@/views/wallet/WalletActivity.vue'));

const FavoriteWallets = () => lazyLoadRetry(() => import(/* webpackChunkName: "favorite-wallets" */ '@/views/wallet/FavoriteWallets.vue'));

const Settings = () => lazyLoadRetry(() => import(/* webpackChunkName: "settings" */ '@/views/settings/Settings.vue'));

const ApiTokens = () => lazyLoadRetry(() => import(/* webpackChunkName: "api-tokens" */ '@/views/settings/ApiTokens.vue'));

const LinkedWallets = () => lazyLoadRetry(() => import(/* webpackChunkName: "linked-wallets" */ '@/views/settings/LinkedWallets.vue'));

const BillingInfo = () => lazyLoadRetry(() => import(/* webpackChunkName: "billing-info" */ '@/views/settings/BillingInfo.vue'));

const Watchlists = () => lazyLoadRetry(() => import(/* webpackChunkName "collection-watchlists" */ '@/views/watchlist/Watchlists.vue'));
const Automation = () => lazyLoadRetry(() => import(/* webpackChunkName "automation" */ '@/views/Automation.vue'));
const ManageAutomations = () => lazyLoadRetry(() => import(/* webpackChunkName "automation" */ '@/views/automation/ManageAutomations.vue'));
const AutomationWallets = () => lazyLoadRetry(() => import(/* webpackChunkName "automation" */ '@/views/automation/AutomationWallets.vue'));
const Templates = () => lazyLoadRetry(() => import(/* webpackChunkName "automation-templates" */ '@/views/automation/Templates.vue'));
const CreateAutomation = () => lazyLoadRetry(() => import(/* webpackChunkName "automation" */ '@/views/CreateAutomation.vue'));
const UpdateAutomationTemplate = () => lazyLoadRetry(() => import(/* webpackChunkName "automation" */ '@/views/automation/UpdateAutomationTemplate.vue'));
const CreateAutomationTemplate = () => lazyLoadRetry(() => import(/* webpackChunkName "automation-templates" */ '@/views/CreateAutomationTemplate.vue'));
const AutomationActions = () => lazyLoadRetry(() => import(/* webpackChunkName "automation" */ '@/views/automation/AutomationActions.vue'));
const CreateAutomationAction = () => lazyLoadRetry(() => import(/* webpackChunkName "automation" */ '@/views/automation/CreateAutomationAction.vue'));
const UpdateAutomationAction = () => lazyLoadRetry(() => import(/* webpackChunkName "automation" */ '@/views/automation/UpdateAutomationAction.vue'));
const SingleAutomation = () => lazyLoadRetry(() => import(/* webpackChunkName "automation" */ '@/views/SingleAutomation.vue'));

const SingleWatchlist = () => lazyLoadRetry(() => import(/* webpackChunkName "collection-watchlists" */ '@/views/watchlist/SingleWatchlist.vue'));
const SingleWalletWatchlist = () => lazyLoadRetry(() => import(/* webpackChunkName "wallet-watchlists" */ '@/views/watchlist/SingleWalletWatchlist.vue'));
const WatchlistPortfolioOverview = () => lazyLoadRetry(() => import(/* webpackChunkName "wallet-watchlists" */ '@/components/watchlists/WatchlistPortfolioOverview.vue'));
const WatchlistPortfolioTokens = () => lazyLoadRetry(() => import(/* webpackChunkName "wallet-watchlists" */ '@/components/watchlists/WatchlistPortfolioTokens.vue'));
const WatchlistPortfolioAnalytics = () => lazyLoadRetry(() => import(/* webpackChunkName "wallet-watchlists" */ '@/components/watchlists/WatchlistPortfolioAnalytics.vue'));
const WatchlistActivity = () => lazyLoadRetry(() => import(/* webpackChunkName: "wallet-transactions" */ '@/views/watchlist/WatchlistActivity.vue'));
const WalletFlipsView = () => lazyLoadRetry(() => import(/* webpackChunkName "wallet-flips" */ '@/views/wallet/WalletFlipsView.vue'));
const WalletWatchlistFlipsView = () => lazyLoadRetry(() => import(/* webpackChunkName "wallet-flips" */ '@/views/watchlist/WalletWatchlistFlipsView.vue'));
const Feed = () => lazyLoadRetry(() => import(/* webpackChunkName "feed" */ '@/views/Feed.vue'));
const Portfolio = () => lazyLoadRetry(() => import(/* webpackChunkName "portfolio" */ '@/views/Portfolio.vue'));
const PageNotFound = () => lazyLoadRetry(() => import(/* webpackChunkName "404" */ '@/views/PageNotFound.vue'));

const AutomationMarketplace = () => lazyLoadRetry(() => import(/* webpackChunkName "automation-marketplace" */ '@/views/automation/Marketplace.vue'));
const AutomationCredits = () => lazyLoadRetry(() => import(/* webpackChunkName "automation-credits" */ '@/views/automation/Credits.vue'));
const AutomationMarketplaceTemplate = () => lazyLoadRetry(() => import(/* webpackChunkName "automation-marketplace" */ '@/views/automation/marketplace/SingleTemplate.vue'));

const AccountUpgrade = () => lazyLoadRetry(() => import(/* webpackChunkName "account-upgrade" */ '@/views/account/Upgrade.vue'));

const walletComponents = {
  wallet: {
    analytics: WalletAnalytics,
    flips: WalletFlipsView,
    portfolio: WalletPortfolioOverviewVue,
    tokens: WalletPortfolioTokensVue,
    activity: WalletTransactions,
  },
  walletWatchlist: {
    analytics: WatchlistPortfolioAnalytics,
    flips: WalletWatchlistFlipsView,
    portfolio: WatchlistPortfolioOverview,
    tokens: WatchlistPortfolioTokens,
    activity: WatchlistActivity,
  },
};

const createWalletChildren = (nameType, componentName) => ([
  {
    path: 'overview',
    name: routesNames[nameType].analytics,
    component: walletComponents[componentName].analytics,
    meta: {
      key: routesNames[nameType].analytics,
      title: 'Overview',
    },
  },
  {
    path: 'flips',
    name: routesNames[nameType].flips,
    component: walletComponents[componentName].flips,
    meta: {
      key: routesNames[nameType].flips,
      title: 'Flips',
    },
  },
  {
    path: 'collections',
    name: routesNames[nameType].portfolio,
    component: walletComponents[componentName].portfolio,
    meta: {
      key: routesNames[nameType].portfolio,
      title: 'Collections',
    },
  },
  {
    path: 'tokens',
    name: routesNames[nameType].items,
    component: walletComponents[componentName].tokens,
    meta: {
      key: routesNames[nameType].items,
      title: 'Tokens',
    },
  },
  {
    path: 'activity',
    name: routesNames[nameType].transactions,
    component: walletComponents[componentName].activity,
    meta: {
      key: routesNames[nameType].transactions,
      title: 'Activity',
    },
  },
]);

const routes = [
  {
    path: '/',
    redirect: '/app',
  },
  {
    path: '/academy',
    name: 'academy',
    beforeEnter(...args) {
      window.open('https://academy.compass.art', '_blank');
      setTimeout(() => {
        window.history.back();
      }, 500);
    },

  },
  {
    path: '/app',
    redirect: '/collections/trending',
  },
  {
    path: '/account/upgrade',
    name: routesNames.accountUpgrade,
    component: AccountUpgrade,
    meta: {
      freeAllowed: true,
    },
  },
  {
    path: '/automations',
    name: 'AutomationsRoot',
    component: Automation,
    meta: {

    },
    children: [
      {
        path: '/automations',
        name: routesNames.automation,
        component: ManageAutomations,
        meta: {
          name: routesNames.automation,
          title: 'Automations',
          fastNavigation: true,
          requiresAuth: true,
        },
      },
      {
        path: '/automations/templates',
        name: routesNames.automationTemplates,
        component: Templates,
        meta: {
          key: routesNames.automationTemplates,
          title: 'Templates',
          fastNavigation: true,

          requiresAuth: true,
        },
      },
      {
        path: '/automations/actions',
        name: routesNames.automationActions,
        component: AutomationActions,
        meta: {
          key: routesNames.automationActions,
          title: 'Actions',
          fastNavigation: true,
          requiresAuth: true,
        },
      },
      {
        path: '/automations/wallets',
        name: routesNames.automationWallets,
        component: AutomationWallets,
        meta: {
          key: routesNames.automationWallets,
          title: 'Wallets',
          fastNavigation: true,
          requiresAuth: true,

        },
      },
      {
        path: '/automations/explore',
        name: routesNames.automationExplore,
        component: AutomationMarketplace,

      },
      {
        path: '/automations/credits',
        name: routesNames.automationCredits,
        component: AutomationCredits,
        meta: {
          requiresAuth: true,

        },
      },
      {
        path: '/automations/marketplace/templates/:id',
        name: routesNames.automationExploreTemplatesShow,
        component: AutomationMarketplaceTemplate,
        meta: {
          requiresAuth: true,

        },
      },

    ],
  },
  {
    path: '/automations/create',
    name: routesNames.automationCreate,
    component: CreateAutomation,
    meta: {

      requiresAuth: true,
    },
  },
  {
    path: '/automations/templates/:automationTemplateId',
    props: true,
    name: routesNames.automationTemplateUpdate,
    component: UpdateAutomationTemplate,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/automations/templates/create',
    name: routesNames.automationTemplateCreate,
    component: CreateAutomationTemplate,
    meta: {

      requiresAuth: true,
    },
  },
  {
    path: '/automations/actions/create',
    name: routesNames.automationActionCreate,
    component: CreateAutomationAction,
    meta: {

      requiresAuth: true,
    },
  },
  {
    path: '/automations/actions/:id',
    name: routesNames.automationActionUpdate,
    component: UpdateAutomationAction,
    meta: {

      requiresAuth: true,
    },
  },
  {
    path: '/automations/:id',
    name: routesNames.automationShow,
    component: SingleAutomation,
    meta: {

      requiresAuth: true,
    },
  },
  {
    path: '/wallets',
    name: routesNames.feed,
    component: Feed,
  },
  {
    path: '/automation',
    redirect: '/automations',
  },

  {
    path: '/alerts',
    name: routesNames.alerts,
    component: Alerts,
  },

  {
    path: '/collections',
    name: routesNames.collections,
    component: Collections,
    redirect: '/collections/trending',
    children: [
      {
        path: 'trending',
        name: routesNames.trendingCollections,
        component: Trending,
        meta: {
          key: routesNames.trendingCollections,
          title: 'Trending',
          fastNavigation: true,
        },
      },

      {
        path: 'minting',
        name: routesNames.mintingCollections,
        component: MintingCollections,
        meta: {
          key: routesNames.mintingCollections,
          title: 'Minting',
          fastNavigation: true,
        },
      },

      {
        path: 'top',
        name: routesNames.topCollections,
        component: Bluechips,
        meta: {
          key: routesNames.topCollections,
          title: 'Top',
          fastNavigation: true,
        },
      },

      {
        path: 'own',
        name: routesNames.ownCollections,
        component: OwnedCollections,
        meta: {
          key: routesNames.ownCollections,
          title: 'Own',
          fastNavigation: true,
        },
      },

      {
        path: 'watchlists',
        name: routesNames.collectionWatchlists,
        component: Watchlists,
        meta: {
          key: routesNames.collectionWatchlists,
          title: 'Watchlists',
          fastNavigation: true,
        },
      },

      {
        path: 'watchlist/:slug',
        name: routesNames.collectionWatchlistsShow,
        component: SingleWatchlist,
        meta: {
          key: routesNames.collectionWatchlistsShow,
          title: '',
          fastNavigation: false,
        },
      },
    ],
  },
  {
    path: '/pulse',
    name: routesNames.pulse,
    component: Pulse,
    redirect: '/pulse/overview',
    children: [
      {
        path: 'overview',
        name: routesNames.pulseOverview,
        component: PulseOverview,
        meta: {
          key: routesNames.pulseOverview,
          title: 'Market Overview',
          fastNavigation: true,
        },
      },
      {
        path: 'leaderboard',
        name: routesNames.pulseLeaderboard,
        component: PulseProfitLeaderboard,
        meta: {
          key: routesNames.pulseLeaderboard,
          title: 'Profit Leaderboard',
          fastNavigation: true,
        },
      },
    ],
  },

  {
    path: '/crates',
    name: 'Crates',
    component: Crates,
  },
  {
    path: '/collections/:contractAddress',
    props: true,
    name: routesNames.collectionShow,
    component: SingleCollection,
    redirect: (to) => ({ name: routesNames.collectionAnalytics, params: { ...to.params } }),

    children: [
      {
        path: 'analytics',
        name: routesNames.collectionAnalytics,
        component: CollectionChartsWrapper,
        meta: {
          key: routesNames.collectionAnalytics,
          title: 'Analytics',
        },
      },

      {
        path: 'mint',
        name: routesNames.collectionMinting,
        component: CollectionMinting,
        meta: {
          key: routesNames.collectionMinting,
          title: 'Mint',
        },
      },

      {
        path: 'holders',
        name: routesNames.collectionHolders,
        component: CollectionHolders,
        meta: {
          key: routesNames.collectionHolders,
          title: 'Holders',
        },
      },

      // {
      //   path: 'social',
      //   name: routesNames.collectionSocialMetrics,
      //   component: CollectionSocialMetrics,
      //   meta: {
      //     key: routesNames.collectionSocialMetrics,
      //     title: 'Social',
      //   },
      // },

      {
        path: 'traits',
        name: routesNames.collectionTraits,
        component: CollectionTraits,
        meta: {
          key: routesNames.collectionTraits,
          title: 'Traits',
        },
      },

      {
        path: 'tokens',
        name: routesNames.collectionItems,
        component: CollectionItems,
        meta: {
          key: routesNames.collectionItems,
          title: 'Tokens',
        },
      },
      {
        path: 'activity',
        name: routesNames.collectionTransactions,
        component: CollectionTransactions,
        meta: {
          key: routesNames.collectionTransactions,
          title: 'Activity',
        },
      },
      {
        path: 'leaderboard',
        name: routesNames.collectionLeaderboard,
        component: CollectionProfitLeaderboard,
        meta: {
          key: routesNames.collectionLeaderboard,
          title: 'Leaderboard',
        },
      },
    ],
  },

  {
    path: '/wallets',
    redirect: '/feed',
  },

  {
    path: '/feed',
    name: routesNames.wallets,
    component: FavoriteWallets,
  },

  {
    path: '/wallet/:address',
    name: routesNames.wallet.show,
    component: SingleWallet,
    redirect: (to) => ({ name: routesNames.wallet.analytics, params: { ...to.params } }),
    props: true,
    children: createWalletChildren('wallet', 'wallet'),
  },
  {
    path: '/portfolio',
    name: routesNames.portfolio.show,
    component: Portfolio,
    children: createWalletChildren('portfolio', 'walletWatchlist'),
    redirect: (to) => ({ name: routesNames.portfolio.analytics }),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/wallets/watchlists/:slug',
    name: routesNames.walletWatchlist.show,
    component: SingleWalletWatchlist,
    redirect: (to) => ({ name: routesNames.walletWatchlist.analytics, params: { ...to.params } }),
    props: true,
    meta: {
      title: 'Watchlist',
    },
    children: createWalletChildren('walletWatchlist', 'walletWatchlist'),
  },
  {
    path: '/settings',
    name: 'Settings',
    component: Settings,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/settings/api',
    name: 'Settings.Api',
    component: ApiTokens,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/settings/authentication',
    name: 'Settings.LinkedWallets',
    component: LinkedWallets,
    meta: {
      requiresAuth: true,
    },
  },

  {
    path: '/settings/billing-info',
    name: 'Settings.BillingInfo',
    component: BillingInfo,
    meta: {
      requiresAuth: true,
    },
  },

  { path: '/:pathMatch(.*)*', component: PageNotFound },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    const toMatched = to.matched;
    const fromMatched = from.matched;
    if (
      (toMatched.length && fromMatched.length)
      && (toMatched[0]?.name === fromMatched[0]?.name)
      && isEqual(to.params, from.params)
      && isEqual(to.query, from.query)
    ) {
      return {};
    }

    if (to.hash) {
      return {
        selector: to.hash,
      };
    }

    if (savedPosition) {
      return savedPosition;
    }

    return { left: 0, top: 0 };
  },
});

export default router;
