/* eslint-disable no-loop-func */
/* eslint-disable guard-for-in */
import {
  ref,
} from 'vue';

const isModalVisible = ref(false);

const resolveFn = ref(null);
const rejectFn = ref(null);

const variableModel = ref(null);

const type = ref(null);

export default function useVariableEditor() {
  const show = ({
    variable, schema = null,
  }) => new Promise((resolve, reject) => {
    variableModel.value = variable;
    type.value = schema.type;

    isModalVisible.value = true;

    resolveFn.value = resolve;
    rejectFn.value = reject;
  });

  const update = (value) => {
    resolveFn.value(value);
    isModalVisible.value = false;
  };

  return {
    isModalVisible,
    show,
    update,
    model: variableModel,
    type,
  };
}
