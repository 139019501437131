/* eslint-disable no-underscore-dangle */
import { ref, computed, onUnmounted } from 'vue';

export function useIntersectionObserver({
  root = null,
  target,
  cbWhenShow = () => {},
  cbWhenHide = () => {},
  options = {},
}) {
  const defaultOptions = {
    root,
    rootMargin: '0px',
    threshold: 1.0,
  };

  const observerOptions = {
    ...defaultOptions,
    ...options,
  };

  const _isIntersecting = ref(undefined);

  const isIntersecting = computed({
    get() {
      return _isIntersecting.value;
    },
    set(val) {
      // to skip initial rendering
      if (typeof _isIntersecting.value !== 'undefined' && !val) {
        cbWhenHide();
      } else if (val) {
        cbWhenShow();
      }

      _isIntersecting.value = val;
    },
  });

  function observerCallback(entries) {
    entries.forEach((entry) => {
      isIntersecting.value = entry.isIntersecting;
    });
  }

  function initIntersectionObserver() {
    const observer = new IntersectionObserver(observerCallback, observerOptions);
    observer.observe(target.value);

    onUnmounted(() => {
      observer.disconnect();
    });
  }

  return {
    initIntersectionObserver,
    isIntersecting,
  };
}
