export default function mapSettings(settingsToMap) {
  const computed = {};
  Object.keys(settingsToMap).forEach((componentField) => {
    let fieldData = settingsToMap[componentField];

    if (typeof fieldData !== 'object') {
      fieldData = {
        key: fieldData,
        default: undefined,
      };
    }

    function getKey() {
      let setting = fieldData.key;
      if (typeof setting === 'function') {
        setting = setting.bind(this)();
      }

      return setting;
    }

    computed[componentField] = {
      get() {
        const value = this.$store.getters['settings/getValue'](getKey());

        if (typeof value === 'undefined' && typeof fieldData.default === 'function') {
          return fieldData.default.bind(this)();
        }

        if (typeof value === 'undefined') {
          return fieldData.default;
        }

        return value;
      },
      set(value) {
        this.$store.dispatch('settings/setValue', { key: getKey(), value });
      },
    };
  });

  return {
    computed: {
      ...computed,
    },
  };
}
