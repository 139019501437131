import { isEqual } from 'lodash-es';
import { computed } from 'vue';

export default function useValidation(schema, model, { customErrorMap } = {}) {
  const isValid = computed(() => {
    try {
      schema.value.parse(model.value);
      return true;
    } catch (error) {
      return false;
    }
  });
  const validationIssues = computed(() => {
    try {
      schema.value.parse(model.value, {
        errorMap: customErrorMap,
      });
      return [];
    } catch (error) {
      // console.error(error, schema.value, model.value);
      return error.errors;
    }
  });

  const getError = (path) => {
    const error = validationIssues.value.find((e) => isEqual(e.path, path));
    return error ? error.message : '';
  };

  return {
    isValid,
    validationIssues,
    getError,
  };
}
