const periodTypeMap = {
  m: 'minute',
  h: 'hour',
  d: 'day',
  y: 'year',
};

export function dateRangeFromPeriod(period, end) {
  const periodRegex = /([0-9]{1,3})([mhdy])/;

  // eslint-disable-next-line
  const [str, length, type] = period.match(periodRegex);
  const start = end.clone().subtract(length, periodTypeMap[type]);

  return {
    start,
    end,
    length: Math.abs(start.diff(end, 'seconds')),
    previous: {
      start: start.clone().subtract(length, periodTypeMap[type]),
      end: start.clone(),
    },
  };
}
