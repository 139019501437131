/* eslint-disable no-use-before-define */
import { ref, computed } from 'vue';

export function useClickAway({
  onClose = () => null,
  onClosed = () => null,
  onOpen = () => null,
  onOpened = () => null,
} = {}) {
  const clickAwayRoot = ref(null);
  const isDisabled = ref(false);

  const $isOpen = ref(false);
  const isOpen = computed({
    get() {
      return $isOpen.value;
    },

    set(val) {
      listenerKeeper(val);
      $isOpen.value = val;
    },
  });

  function toggle() {
    if (!isOpen.value) {
      onOpen();
    } else {
      onClose();
    }

    isOpen.value = !isOpen.value;

    if (isOpen.value) {
      onOpened();
    } else {
      onClosed();
    }
  }

  function forceClose() {
    onClose();
    isOpen.value = false;
    onClosed();
  }

  function forceOpen() {
    onOpen();
    isOpen.value = true;
    onOpened();
  }

  // provide
  function disableClickOutside() {
    isDisabled.value = true;
  }

  // provide
  function enableClickOutside() {
    isDisabled.value = false;
  }

  function handleClick(e) {
    if (isDisabled.value || clickAwayRoot.value.contains(e.target)) {
      return;
    }
    forceClose();
  }

  function setListener() {
    document.addEventListener('click', handleClick);
  }

  function removeListener() {
    document.removeEventListener('click', handleClick);
  }

  function listenerKeeper(val) {
    if (val) {
      setListener();
    } else {
      removeListener();
    }
  }

  return {
    clickAwayRoot,
    isOpen,
    toggle,
    forceClose,
    forceOpen,
    disableClickOutside,
    enableClickOutside,
    isDisabled,
    removeListener,
  };
}
