import { MessageTypes } from 'subscriptions-transport-ws';

function restartWebsockets(wsClient) {
  // Copy current operations
  const operations = { ...wsClient.operations };

  // Close connection
  wsClient.close(true);

  // Open a new one
  wsClient.connect();

  // Push all current operations to the new connection
  Object.keys(operations).forEach((id) => {
    wsClient.sendMessage(
      id,
      MessageTypes.GQL_START,
      operations[id].options,
    );
  });
}

export async function onLogin(apolloClient) {
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient);
  try {
    apolloClient.stop();
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (apolloClient.wsClient) {
    restartWebsockets(apolloClient.wsClient);
  }
  try {
    apolloClient.stop();
    await apolloClient.resetStore();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}
