import {
  ApolloClient,
} from '@apollo/client/core';

import v2ClientOptions from './client-options/v2';

const apolloClients = {
  default: new ApolloClient(v2ClientOptions),
};

export default apolloClients;
