import { EncryptStorage } from 'encrypt-storage';

let storage = null;
export const init = (key) => {
  storage = new EncryptStorage(key, {
    instance: 'remote-signing',
  });
};

export const get = (key) => storage.getItem(key);

export const set = (key, value) => storage.setItem(key, value);
