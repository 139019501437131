import gql from 'graphql-tag';
import collectionWatchlistFields from '@/graphql/watchlists/fragments/collectionWatchlistFields.fragment.gql';
import walletWatchlistFields from '@/graphql/watchlists/fragments/walletWatchlistFields.fragment.gql';

export const CREATE_COLLECTION_WATCHLIST_MUTATION = gql`
mutation CreateCollectionWatchlist($name: String!) {
  watchlist: createCollectionWatchlist(name: $name) {
    ...CollectionWatchlist_Fields
  }
}
${collectionWatchlistFields}
`;

export const CREATE_WALLET_WATCHLIST_MUTATION = gql`
mutation CreateWalletWatchlist($name: String!) {
  watchlist: createWalletWatchlist(name: $name) {
    ...WalletWatchlist_Fields
  }
}
${walletWatchlistFields}
`;

export const CREATE_DRAFT_WALLET_WATCHLIST_MUTATION = gql`
mutation CreateDraftWalletWatchlist($walletIds: [String!]!, $name: String) {
  watchlist: createOrGetWalletWatchlist(walletIds: $walletIds, name: $name) {
    ...WalletWatchlist_Fields
  }
}
${walletWatchlistFields}
`;
