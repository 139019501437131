/* eslint-disable no-case-declarations */
import { FILTER_COMPONENTS, FILTER_TYPES } from '@/constants/filters';
import { isEqual, keyBy, partition } from 'lodash-es';
import {
  ref, watch, unref, computed, onMounted,
} from 'vue';
import moment from 'moment';
import { formatAddress } from '@/utils/filters';
import { useApolloClient } from '@vue/apollo-composable';
import pluralize from 'pluralize';
import { usePremium } from '../premium';

export const comparatorMap = {
  number: {
    eq: 'equals to',
    gt: 'greater than',
    lt: 'less than',
    neq: 'not equals to',
  },
  timestamp: {
    eq: 'at',
    gt: 'after',
    lt: 'before',
  },
};

const validateFilter = (options) => {
  if (unref(options.value) === null) {
    return false;
  }

  const d = unref(options.value);

  if (options.type === 'number' && d.value === null) {
    return false;
  }

  if (options.type === 'collection' && !d.value?.length) {
    return false;
  }

  if (['collection', 'wallet'].includes(options.type) && ['in', 'nin'].includes(d.comparator) && !d.value?.length) {
    return false;
  }

  if (options.type === FILTER_TYPES.LOGICAL) {
    return options.value.length > 0;
  }

  if (options.type === FILTER_TYPES.NUMERIC_TRAIT) {
    return !Number.isNaN(Number(d.value)) && d.traitId && d.comparator;
  }

  return true;
};

const pinnedFilters = ref({});

export default function useFilters(filters, modelValue, group = ref(null)) {
  // modelValue is the value of the filter model
  // it is used to initialize the filters
  const { requirePremium } = usePremium();
  const filtersByKey = computed(() => keyBy(filters.value, 'key'));
  const pinned = computed(() => (
    group.value
    && pinnedFilters.value[group.value]?.length > 0 ? pinnedFilters.value[group.value] : []
  ).filter((item) => !!unref(filters).find((filter) => filter.key === item.key && filter.type === item.type)));

  onMounted(() => {
    if (pinned.value.length > 0) {
      modelValue.value = [...pinned.value, ...modelValue.value];
    }
  });
  // on mount check all applicable pinned filters and apply them

  const invalidFilters = ref([]);

  const allFilters = computed(() => [
    ...modelValue.value,
    ...invalidFilters.value,
  ]);

  const addCallbacks = [];
  const onAdded = (callback) => {
    addCallbacks.push(callback);
  };

  const updateCallbacks = [];
  const onUpdated = (callback) => {
    updateCallbacks.push(callback);
  };

  const isPinned = (item) => pinned.value.includes(item);

  const pinFilter = (item) => {
    if (!group.value) {
      return;
    }

    if (!pinnedFilters.value[group.value]) {
      pinnedFilters.value[group.value] = [];
    }

    if (isPinned(item)) {
      pinnedFilters.value[group.value] = pinnedFilters.value[group.value].filter((i) => i !== item);
      return;
    }

    pinnedFilters.value[group.value] = [...pinnedFilters.value[group.value], item];
  };

  const addFilter = async (filter) => {
    if (modelValue.value.length > 0) {
      await requirePremium('to add more filters');
    }
    const d = {
      key: filter.key,
      value: ref(structuredClone(filter.defaultValue) || null),
      type: filter.type,
    };

    if (validateFilter(d)) {
      modelValue.value = [...modelValue.value, d];
    } else {
      invalidFilters.value = [...invalidFilters.value, d];
    }

    addCallbacks.forEach((item) => item(d));

    if (filter.autoPin) {
      pinFilter(d);
    }
  };

  const filterConfig = (filterKey, configKey, defaultValue = null) => {
    const filter = filtersByKey.value[filterKey];
    if (!filter) {
      return defaultValue;
    }

    return filter[configKey] || defaultValue;
  };

  watch(() => invalidFilters.value, (newValue, oldValue) => {
    if (isEqual(newValue, oldValue)) {
      return;
    }

    const [valid, invalid] = partition(invalidFilters.value, (item) => validateFilter(item));
    if (valid.length > 0) {
      modelValue.value = [...modelValue.value, ...valid];
      invalidFilters.value = invalid || [];
    }
  }, { deep: true });

  watch(() => modelValue.value, (value) => {
    value.forEach((item) => {
      if (!isPinned(item) && filtersByKey.value[item.key]?.autoPin) {
        pinFilter(item);
      }
    });
  }, { deep: true });

  const removeFilter = (item) => {
    if (modelValue.value.includes(item)) {
      modelValue.value = modelValue.value.filter((filter) => filter !== item);
    }

    if (invalidFilters.value.includes(item)) {
      invalidFilters.value = invalidFilters.value.filter((filter) => filter !== item);
    }

    if (group.value && pinnedFilters.value[group.value]?.includes(item)) {
      pinnedFilters.value[group.value] = pinnedFilters.value[group.value].filter((filter) => filter !== item);
    }
  };

  const removeAll = () => {
    modelValue.value = [];
  };

  const filterComponent = (type, key) => filtersByKey.value?.[key]?.component || FILTER_COMPONENTS[type];

  const filterName = (key) => unref(filters).find((item) => item.key === key)?.name;

  const filterProps = (key) => unref(filters).find((item) => item.key === key)?.props;

  const { resolveClient } = useApolloClient();
  const client = resolveClient();

  const getModelName = (type, id) => {
    if (type === 'wallet') {
      return client.cache.data.data[`Wallet:${id}`]?.displayName || formatAddress(id);
    }

    if (type === 'walletWatchlist') {
      return client.cache.data.data[`WalletWatchlist:${id}`]?.name || id;
    }

    if (type === 'collection') {
      return client.cache.data.data[`Collection:${id}`]?.name || formatAddress(id);
    }

    return formatAddress(id);
  };

  const filterDisplayValue = (item) => {
    const filter = unref(filters).find((f) => f.key === item.key);
    if (typeof item.value === 'undefined' || item.value === null) {
      return 'not set';
    }

    switch (item.type) {
      case 'boolean':
        return item.value ? 'yes' : 'no';
      case 'timestamp':
        return `${comparatorMap[item.type][item.value.comparator]} ${moment(item.value.value).format('lll')}`;
      case 'category':
        return filter.props.multiple ? item.value?.join(', ') : item.value;
      case 'number':
        return `${comparatorMap[item.type][item.value.comparator]} ${item.value.value}`;
      case 'interval':
        return `${comparatorMap[FILTER_TYPES.NUMBER][item.value.comparator]} ${item.value.value ? item.value.value / 60 / 60 : '--'} ${pluralize('hour', item.value.value / 60 / 60)}`;
      case 'collection':
      case 'wallet':
        if (!item.value.value?.length && !['null', 'notNull'].includes(item.value.comparator)) {
          return 'invalid value';
        }
        if (['null', 'notNull'].includes(item.value.comparator)) {
          return item.value.comparator === 'null' ? 'is empty' : 'is not empty';
        }

        if (item.value.value?.startsWith?.('{{') && item.value.value?.endsWith?.('}}')) {
          return `${item.value.comparator === 'in' ? 'is' : 'is not'} ${item.value.value.replace('{{', '').replace('}}', '')}`;
        }

        const modelName = getModelName(item.value.valueType || item.type, item.value.value[0]);

        // eslint-disable-next-line no-nested-ternary
        return `${(item.value.value.length > 1 ? (item.value.comparator === 'in' ? 'one of' : 'not one of') : (item.value.comparator === 'in' ? 'is' : 'is not'))} ${item.value.value.length > 1 ? item.value.value.length : modelName}`;
      default:
        return item.value;
    }
  };

  const updateFilterValue = ({
    item, value, type, key,
  }) => {
    if (typeof value !== 'undefined') {
      item.value = value;
    }

    item.type = type || item.type;
    item.key = key || item.key;
    // validate and reorganize invalid filters
    if (validateFilter(item) && invalidFilters.value.includes(item)) {
      invalidFilters.value = invalidFilters.value.filter((filter) => filter !== item);
      modelValue.value = [...modelValue.value, item];
      // addCallbacks.forEach((i) => i(item));
    }

    if (!validateFilter(item) && !invalidFilters.value.includes(item)) {
      invalidFilters.value = [...invalidFilters.value, item];
      modelValue.value = modelValue.value.filter((filter) => filter !== item);
      // addCallbacks.forEach((i) => i(item));
    }
  };

  return {
    allFilters,
    addFilter,
    pinFilter,
    removeFilter,
    removeAll,
    onUpdated,
    filterConfig,
    onAdded,
    filterComponent,
    filterName,
    filterProps,
    updateFilterValue,
    filterDisplayValue,
    validateFilter,
    isPinned,
  };
}
