import colors from 'tailwindcss/colors';

// const typeColor = {
//   mint: 'bg-blue-700 text-blue-100',
//   burn: 'bg-red-700 text-red-100',
//   sale: 'bg-green-700 text-green-100',
//   transfer: 'bg-gray-700 text-gray-100',
//   list: 'bg-sky-600 text-sky-100',
//   bid: 'bg-purple-600 text-purple-100',
// };

export const ACTIVITY_TYPE_TO_COLOR = {
  transfer: colors.gray[500],
  sale: colors.green[500],
  buy: colors.green[500],
  sell: colors.orange[500],
  mint: colors.blue[500],
  list: colors.sky[500],
  burn: colors.red[500],
  bid: colors.purple[500],
};

export const ACTIVITY_TYPE_TO_COLOR_INDEX = {
  transfer: 11,
  sale: 3,
  buy: 3,
  sell: 4,
  mint: 2,
  list: 12,
  burn: 5,
  bid: 7,
};
