import { useLazyQuery, useMutation, useQuery } from '@vue/apollo-composable';
import { useStore } from 'vuex';
import { computed, ref, unref } from 'vue';
import routesNames from '@/router/routesNames';
import {
  GET_COLLECTION_WATCHLISTS_QUERY,
  GET_WALLET_WATCHLISTS_QUERY,
  GET_COLLECTION_WATCHLISTS_WITH_MEMBERSHIPS,
  GET_WALLET_WATCHLISTS_WITH_MEMBERSHIPS,
} from '@/graphql/watchlists/queries';
import {
  CREATE_COLLECTION_WATCHLIST_MUTATION,
  CREATE_WALLET_WATCHLIST_MUTATION,
  DELETE_WATCHLIST_MUTATION,
  UPDATE_COLLECTION_WATCHLIST_MUTATION,
  UPDATE_WALLET_WATCHLIST_MUTATION,
  ADD_COLLECTION_TO_WATCHLIST,
  REMOVE_COLLECTION_FROM_WATCHLIST,
  ADD_WALLET_TO_WATCHLIST,
  REMOVE_WALLET_FROM_WATCHLIST,
  CREATE_DRAFT_WALLET_WATCHLIST_MUTATION,
} from '@/graphql/watchlists/mutations';
import { WATCHLIST_TYPES } from '@/constants';
import { useUserStore } from '../user';

const watchlistCreatedCallbacks = [];
const callWatchlistCreatedCallbacks = (result) => watchlistCreatedCallbacks.forEach((item) => item(result));
const onWatchlistCreated = (callback) => { watchlistCreatedCallbacks.push(callback); };

const watchlistMembersUpdatedCallbacks = [];
const callWatchlistMembersUpdatedCallbacks = (result) => watchlistMembersUpdatedCallbacks.forEach((item) => item(result));
const onWatchlistMembersUpdated = (callback) => { watchlistMembersUpdatedCallbacks.push(callback); };

export function useWatchlists(type, memberCheckId = ref(null)) {
  const WATCHLISTS_QUERY = unref(type) === WATCHLIST_TYPES.COLLECTION ? GET_COLLECTION_WATCHLISTS_QUERY : GET_WALLET_WATCHLISTS_QUERY;
  const WATCHLISTS_WITH_MEMBERSHIPS_QUERY = unref(type) === WATCHLIST_TYPES.COLLECTION ? GET_COLLECTION_WATCHLISTS_WITH_MEMBERSHIPS : GET_WALLET_WATCHLISTS_WITH_MEMBERSHIPS;
  const refetchQueries = [
    { query: WATCHLISTS_QUERY },
    ...(memberCheckId?.value ? [{ query: WATCHLISTS_WITH_MEMBERSHIPS_QUERY, variables: { id: memberCheckId?.value } }] : []),
  ];

  /** WATCHLIST CREATING */
  const isCreateModalVisible = ref(false);
  const showCreateWatchlist = () => {
    isCreateModalVisible.value = true;
  };
  const newWatchlistName = ref('');
  const canCreate = computed(() => Boolean(newWatchlistName.value?.length));
  const isCreating = ref(false);

  const {
    mutate: createWatchlistMutation,
    onDone: onWatchlistCreatedMutationDone,
  } = useMutation(
    unref(type) === WATCHLIST_TYPES.COLLECTION ? CREATE_COLLECTION_WATCHLIST_MUTATION : CREATE_WALLET_WATCHLIST_MUTATION,
    {
      refetchQueries,
      awaitRefetchQueries: true,
    },
  );

  onWatchlistCreatedMutationDone((r) => {
    callWatchlistCreatedCallbacks(r.data.watchlist);
  });

  const createWatchlist = (name = null) => {
    if (name === null && !canCreate.value) {
      return Promise.reject();
    }

    isCreating.value = true;

    return createWatchlistMutation({ name: name || newWatchlistName.value }).then((res) => {
      isCreateModalVisible.value = false;
      return res;
    }).finally(() => {
      isCreating.value = false;
      newWatchlistName.value = '';
    });
  };

  const { onLogin, isLoggedIn } = useUserStore();

  /** GET WATCHLISTS */
  const {
    load: loadCollectionWatchlist,
    result,
    loading: isLoadingWatchlists,
    refetch,
  } = useLazyQuery(WATCHLISTS_QUERY);
  function fetchColllectionWatchlist() {
    loadCollectionWatchlist();
    const watchlists = computed(() => result.value?.watchlists || []);

    onLogin(() => {
      refetch();
    });

    return {
      isLoadingWatchlists,
      watchlists,
    };
  }

  function getWatchlists() {
    loadCollectionWatchlist();
    const watchlists = computed(() => result.value?.watchlists || []);

    onLogin(() => {
      refetch();
    });

    return {
      isLoadingWatchlists,
      watchlists,
    };
  }

  const {
    load: loadWatchlistWithMemberCheck,
    result: watchlistWithMemberCheckResult,
  } = useLazyQuery(
    WATCHLISTS_WITH_MEMBERSHIPS_QUERY,
    () => ({ id: memberCheckId.value }),
    () => ({
      enabled: isLoggedIn.value && memberCheckId.value !== null,
    }),
  );
  function fetchWatchlistWithMemberCheck() {
    loadWatchlistWithMemberCheck();
    return {
      watchlistWithMemberCheck: computed(() => watchlistWithMemberCheckResult.value?.watchlists || []),
    };
  }

  /** DELETE WATCHLIST */
  const {
    mutate: deleteWatchlist,
  } = useMutation(
    DELETE_WATCHLIST_MUTATION,
    {
      refetchQueries,
      awaitRefetchQueries: true,
    },
  );

  /** UPDATE WATCHLIST */
  const {
    mutate: updateWatchlist,
  } = useMutation(
    unref(type) === WATCHLIST_TYPES.COLLECTION ? UPDATE_COLLECTION_WATCHLIST_MUTATION : UPDATE_WALLET_WATCHLIST_MUTATION,
    {
      refetchQueries,
      awaitRefetchQueries: true,
    },
  );

  /** ADD COLLECTION TO WATCHLIST */
  const {
    mutate: add,
    onDone: onWatchlistMembersAddedMutationDone,
  } = useMutation(
    unref(type) === WATCHLIST_TYPES.COLLECTION ? ADD_COLLECTION_TO_WATCHLIST : ADD_WALLET_TO_WATCHLIST,
    {
      refetchQueries,
      awaitRefetchQueries: true,
    },
  );

  /** REMOVE COLLECTION FROM WATCHLIST */
  const {
    mutate: remove,
    onDone: onWatchlistMembersRemovedMutationDone,
  } = useMutation(
    unref(type) === WATCHLIST_TYPES.COLLECTION ? REMOVE_COLLECTION_FROM_WATCHLIST : REMOVE_WALLET_FROM_WATCHLIST,
    {
      refetchQueries,
      awaitRefetchQueries: true,
    },
  );

  onWatchlistMembersAddedMutationDone(() => callWatchlistMembersUpdatedCallbacks());
  onWatchlistMembersRemovedMutationDone(() => callWatchlistMembersUpdatedCallbacks());

  /** Generate watchlist routing object */
  const getWatchlistRoute = (item) => ({ name: unref(type) === WATCHLIST_TYPES.COLLECTION ? routesNames.collectionWatchlistsShow : routesNames.walletWatchlist.show, params: { slug: item.slug } });

  function createAndAdd(modelIds) {
    createWatchlist();
    return new Promise((resolve, reject) => {
      onWatchlistCreated((res) => {
        add({ id: res.id, modelIds })
          .then(resolve)
          .catch(reject);
      });
    });
  }

  const store = useStore();

  const {
    mutate: createDraftWalletWatchlistMutation,
  } = useMutation(CREATE_DRAFT_WALLET_WATCHLIST_MUTATION);

  const getDraftWalletWatchlist = (modelIds, name) => createDraftWalletWatchlistMutation({ walletIds: modelIds, name });

  return {
    showCreateWatchlist,
    newWatchlistName,
    canCreate,
    isCreating,
    fetchColllectionWatchlist,
    getWatchlistRoute,
    fetchWatchlistWithMemberCheck,
    isCreateModalVisible,
    createWatchlist,
    createAndAdd,
    updateWatchlist: (...args) => updateWatchlist(...args).then(async (res) => {
      // remove from pinned
      if (unref(type) === WATCHLIST_TYPES.COLLECTION) {
        await store.dispatch('auth/fetchUserPinnedWatchlists');
      }

      return res;
    }),
    deleteWatchlist: (...args) => deleteWatchlist(...args).then(async (res) => {
      // remove from pinned
      if (unref(type) === WATCHLIST_TYPES.COLLECTION) {
        await store.dispatch('auth/fetchUserPinnedWatchlists');
      }

      return res;
    }),
    add,
    remove,
    onWatchlistCreated,
    onWatchlistMembersUpdated,
    getDraftWalletWatchlist,
    // export raw mutations
    createWatchlistMutation,
    getWatchlists,
  };
}
