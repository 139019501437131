import gql from 'graphql-tag';

export const REMOVE_COLLECTION_FROM_WATCHLIST = gql`
mutation RemoveCollectionsFromWatchlist($id: Int!, $modelIds: [String!]!) {
  removeCollectionsFromWatchlist(watchlistId: $id, collectionIds: $modelIds) {
    id
    memberCount
  }
}
`;

export const REMOVE_WALLET_FROM_WATCHLIST = gql`
mutation RemoveWalletsFromWatchlist($id: Int!, $modelIds: [String!]!) {
  removeWalletsFromWatchlist(watchlistId: $id, walletIds: $modelIds) {
    id
  }
}
`;
