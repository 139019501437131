import { useUiDialog } from '@/components/ui-dialog/useUiDialog';

const $key = 'lazy-load-refreshed';
export function lazyLoadRetry(compImportFn) {
  return new Promise((resolve, reject) => {
    const wasRefreshed = JSON.parse(sessionStorage.getItem($key) || 'false');

    compImportFn()
      .then((component) => {
        sessionStorage.setItem($key, 'false');
        resolve(component);
      })
      .catch((err) => {
        if (!wasRefreshed) {
          sessionStorage.setItem($key, 'true');
          const dialog = useUiDialog();
          const SECONDS_AMOUNT = 5;
          const TIME_TO_RELOAD = 1000 * SECONDS_AMOUNT;

          dialog.info(
            `In ${SECONDS_AMOUNT} seconds, page will be reloaded to update cached data`,
            { icon: 'far fa-exclamation-triangle text-lg text-red-600' },
          );

          return setTimeout(() => window.location.reload(), TIME_TO_RELOAD);
        }

        return reject(err);
      });
  });
}
