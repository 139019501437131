import { useQuery } from '@vue/apollo-composable';
import {
  ref, isRef, computed, watch,
} from 'vue';

// Taken from vue apollo
export function paramToRef(param) {
  if (isRef(param)) {
    return param;
  } if (typeof param === 'function') {
    return computed(param);
  }
  return ref(param);
}

export default function usePaginatedQuery({ take, hasMoreCheck, mergeResults }, query, variables, options) {
  const baseVariables = paramToRef(variables);
  // if base variables change we need to reset skip
  const initialVariables = computed(() => ({
    ...baseVariables.value,
    take,
    skip: 0,
  }));

  const queryObject = useQuery(query, initialVariables, options);

  const hasMore = ref(false);
  const skip = ref(0);
  let isInitialized = false;
  // required for initial check
  queryObject.onResult(({ loading, data }) => {
    if (loading || skip.value > 0 || isInitialized) {
      return;
    }

    hasMore.value = hasMoreCheck(data);
    isInitialized = true;
  });

  const loadingMore = ref(false);

  watch([() => paramToRef(variables), () => paramToRef(options)], () => {
    isInitialized = false;
  }, { deep: true });

  watch(() => paramToRef(options), () => {
    queryObject.refetch();
  });

  const loadMore = () => {
    loadingMore.value = true;
    return queryObject.fetchMore({
      variables: {
        ...initialVariables.value,
        skip: skip.value + take,
      },
      updateQuery(previousResult, { fetchMoreResult }) {
        hasMore.value = hasMoreCheck(fetchMoreResult);
        return mergeResults(previousResult, fetchMoreResult);
      },
    }).then(() => {
      skip.value += take;
    }).finally(() => {
      loadingMore.value = false;
    });
  };

  return {
    ...queryObject,
    loadingMore,
    loadMore,
    hasMore,
  };
}
