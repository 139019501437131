import gql from 'graphql-tag';

export const GET_COLLECTION_WATCHLIST_METRICS_QUERY = gql`
  query GetCollectionWatchlistMetrics($id: Int!, $period: String) {
    collectionWatchlist(id: $id) {
      memberCount
      marketCapOverTime(period: $period) {
        key
        value
      }
      volumeOverTime(period: $period) {
        key
        value
      }

      volume1d: volume(period: "1d") {
        value
        previous
      }

      volume7d: volume(period: "7d") {
        value
        previous
      }

      uniqueBuyers1d: uniqueBuyersCount(period: "1d") {
        value
        previous
      }

      uniqueSellers1d: uniqueSellersCount(period: "1d") {
        value
        previous
      }

      uniqueBuyers7d: uniqueBuyersCount(period: "7d") {
        value
        previous
      }

      uniqueSellers7d: uniqueSellersCount(period: "7d") {
        value
        previous
      }
    }
  }
  `;
