import gql from 'graphql-tag';

export const ACTION_INPUT_FIELD_FRAGMENT = gql`
fragment ActionInput_fields on ActionInput {
  name
    type
    actingAs
    required
    defaultValue
    description
}
`;
