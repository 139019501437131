import { computed, ref } from 'vue';

const IS_TOUCH_SCREEN = '(pointer: coarse)';
const IS_SM_SCREEN = 'screen and (min-width: 640px)';
const IS_MD_SCREEN = 'screen and (min-width: 768px)';
const IS_LG_SCREEN = 'screen and (min-width: 1024px)';
const IS_XL_SCREEN = 'screen and (min-width: 1280px)';

export function useDevice() {
  const isTouchScreen = computed(() => window.matchMedia(IS_TOUCH_SCREEN).matches);

  const isMinimumSmScreen = computed(() => window.matchMedia(IS_SM_SCREEN).matches);

  const isMinimumMdScreen = computed(() => window.matchMedia(IS_MD_SCREEN).matches);

  const isMinimumLgScreen = computed(() => window.matchMedia(IS_LG_SCREEN).matches);

  const isMinimumXlScreen = computed(() => window.matchMedia(IS_XL_SCREEN).matches);

  return {
    isTouchScreen,
    isMinimumSmScreen,
    isMinimumMdScreen,
    isMinimumLgScreen,
    isMinimumXlScreen,
  };
}
