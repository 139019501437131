import {
  computed,
  ref,
} from 'vue';

import {
  useQuery,
} from '@vue/apollo-composable';

import Web3 from 'web3';
import tokenListingsQuery from '@/graphql/token/queries/tokenListings.query.gql';

import useTracking from '../tracking';
import useOrderManager from '../trade/use-order-manager';

export default function useBuyToken({ collectionId, tokenId }) {
  const { buy } = useOrderManager();
  const {
    result: tokenListingResult,
    refetch: refetchListings,
    onResult: onListingsResult,
    loading: isLoadingListings,
    // load: loadListings,
  } = useQuery(
    tokenListingsQuery,
    () => ({ id: `${collectionId.value}_${tokenId.value}` }),
    () => ({ fetchPolicy: 'cache-and-network' }),
  );
  const listings = computed(() => tokenListingResult.value?.token?.listings);

  const isListed = computed(() => listings.value && listings.value.length > 0);

  const listing = computed(() => listings.value?.[0] || null);

  const error = ref(null);
  const isBuying = ref(false);
  const checkoutText = ref(null);
  const transactionHash = ref(null);
  const isLikelySold = ref(false);

  const steps = ref([]);

  const { track } = useTracking();

  const buyToken = async ({
    maxPriorityFeePerGas,
    maxFeePerGas,
  } = {}) => {
    const value = listing.value.price;
    track('token buy initiated', {
      collectionId: collectionId.value,
      tokenId: collectionId.value,
      value,
    });
    // await this.acceptDisclaimer();
    error.value = null;
    isBuying.value = true;

    try {
      await buy({
        steps,
        orderId: listing.value.id,
        maxPriorityFeePerGas,
        maxFeePerGas,
      });
    } catch (e) {
      if (e.message.includes('user rejected transaction')) {
        error.value = new Error('You cancelled the transaction');
      }

      if (e.message.includes('insufficient funds')) {
        error.value = new Error('Insufficient funds');
      }

      if (e.message.includes('cannot estimate gas')) {
        error.value = new Error('Listing unavailable or not fillable');
      }

      if (!error.value) {
        error.value = new Error(e.response?.data?.message || e.message || 'Something went wrong');
      }

      throw error.value;
    } finally {
      isBuying.value = false;
    }
  };

  const reset = () => {
    isLikelySold.value = false;
    error.value = null;
    isBuying.value = false;
  };

  return {
    listing,
    listings,
    refetchListings,
    isListed,
    buyToken,
    isBuying,
    isLikelySold,
    reset,
    checkoutText,
    error,
    // loadListings,
    onListingsResult,
    isLoadingListings,
    steps,
  };
}
