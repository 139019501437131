import routesNames from '@/router/routesNames';
import { computed, ref, unref } from 'vue';
import { useRouter } from 'vue-router';
import { usePremium } from '../premium';
import { useWatchlists } from './use-watchlists';

export default function useCreateWatchlist(type) {
  const isVisible = ref(false);
  const name = ref('');
  const items = ref([]);
  const canCreate = computed(() => name.value && name.value.length > 0 && items.value.length > 0);
  const isCreating = ref(false);

  const show = (options) => {
    isVisible.value = true;
    name.value = options.name;
    items.value = options.items;
  };

  const { createWatchlistMutation, add } = useWatchlists(unref(type));

  const router = useRouter();

  const { requirePremium } = usePremium();

  const create = async () => {
    await requirePremium(`to create a ${unref(type)}`);
    isCreating.value = true;
    if (!canCreate.value) {
      throw new Error('Cannot create watchlist');
    }

    return createWatchlistMutation({ name: name.value })
      .then(async ({ data: { watchlist } }) => {
        await add({ id: watchlist.id, modelIds: items.value });
        return watchlist;
      })
      .then((watchlist) => {
        isVisible.value = false;

        router.push({
          // eslint-disable-next-line no-underscore-dangle
          name: watchlist.__typename === 'WalletWatchlist' ? routesNames.walletWatchlist.show : routesNames.collectionWatchlistsShow,
          params: {
            slug: watchlist.slug,
          },
        });
      })
      .finally(() => {
        isCreating.value = false;
      });
  };

  return {
    // Flags
    isCreating,
    isVisible,
    canCreate,
    // Global state
    type,
    name,
    items,
    // Methods
    show,
    create,
  };
}
