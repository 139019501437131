import gql from 'graphql-tag';

export const COLLECTION_LIST_ITEM_FIELDS = gql`
  fragment CollectionListItem_Fields on Collection {
    id
    name
    imageUrl
    contractCreatedTimestamp
    totalSupply
    slug
    isVerified
    slug
    externalUrl
    orderbookSyncedAt
    devFeeBasisPoints
    liquidityRank
  }
`;
