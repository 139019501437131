/* eslint-disable no-underscore-dangle */
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';

function createInstance(el, value) {
  if (!value) {
    return;
  }

  let config = value;
  if (typeof value !== 'object') {
    config = {
      content: value,
    };
  }

  tippy(el, { ...config, zIndex: 10100 });
}
export const tooltip = {
  mounted(el, { value }) {
    createInstance(el, value);
  },

  beforeUnmount(el) {
    el._tippy?.destroy();
  },

  updated(el, { value }) {
    if (!value && el._tippy) {
      el._tippy.destroy();
      return;
    }

    if (!value) {
      return;
    }

    if (!el._tippy) {
      createInstance(el, value);
    }

    let config = value;
    if (typeof value !== 'object') {
      config = {
        content: value,
      };
    }

    el._tippy.setProps(config);
  },

};
