export default function workerImitator(fn) {
  const worker = {
    postMessage(dataJson) {
      const data = JSON.parse(dataJson);
      const result = fn(data);

      if (typeof this.onmessage === 'function') {
        this.onmessage({ data: result });
      } else {
        // eslint-disable-next-line no-console
        console.error('[WORKER IMITATOR ERROR]: Define the onmessage listener');
      }
    },

    terminate: () => null,

    onmessage: null,
  };

  return worker;
}
