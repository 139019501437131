import gql from 'graphql-tag';
import Web3 from 'web3';
import Web3Modal from 'web3modal';
import WalletConnectProvider from '@walletconnect/web3-provider';
import { onLogin, onLogout } from '@/vue-apollo/controllers';
import useTracking from '@/composition/tracking';
import { swClearCache } from '@/registerSW';
import { nextTick } from 'vue';

const GET_NONCE_QUERY = gql`
query GetWalletNonce($id: String!) {
  nonce: walletNonce(id: $id)
}
`;

const GET_FEATURE_FLAGS_QUERY = gql`
query GetFeatureFlags {
  featureFlags {
    id
    name
  } 
}
`;

export const GET_CURRENT_USER_QUERY = gql`
query GetUser {
  user: me {
    id
    displayName
    profilePictureUrl
    isPremium
    passBalance
    isDiscordLinked
    premiumExpiresAt
    automationCreditBalance
    role
    discordId
    customerId
    createdAt
    billingName
    billingCountry
    authMethods {
      id
      provider
      providerId
      displayName
      profilePictureUrl
      emailAddress
    }
    wallets {
      id
      name
      openseaProfile
      displayName
      passBalance
    }
  }
  pinnedWatchlists: collectionWatchlists(pinnedOnly: true) {
    id
    name
    slug
  }
}
`;

export const GET_USER_PINNED_WATCHLISTS = gql`
query GetUserPinnedWatchlists {
  pinnedWatchlists: collectionWatchlists(pinnedOnly: true) {
    id
    name
    slug
  }
}
`;

const LINK_WALLET_MUTATION = gql`
mutation LinkWallet($id: String!, $signature: String!, $force: Boolean) {
    linkWallet(id: $id, signature: $signature, force: $force)
}`;

const UNLINK_WALLET_MUTATION = gql`
mutation UnlinkWallet($id: String!) {
    unlinkWallet(id: $id)
}`;

function getMessageToSign(address, nonce) {
  let str = '';
  str += 'Click to prove wallet ownership and sign in to Compass.\n\n';
  str += 'No transaction will be executed by signing this message, it is completely free.\n\n';
  str += 'Your session will expire in 7 days.\n\n';
  str += 'Wallet address:\n';
  str += `${address.toLowerCase()}\n\n`;
  str += 'Nonce:\n';
  str += nonce;

  return str;
}

const providerOptions = {
  // Example with injected providers
  injected: {
    display: {
      // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
      name: 'MetaMask',
      description: 'Connect with the provider in your Browser',
    },
    package: null,
  },
  // Example with WalletConnect provider
  walletconnect: {
    display: {
      // logo: "data:image/gif;base64,INSERT_BASE64_STRING",
      name: 'WalletConnect',
      description: 'Scan QR Code with your mobile wallet',
    },
    package: WalletConnectProvider,
    options: {
      infuraId: '6b36a3b7147146a1b3ea32ca1e2d62c4', // required
    },
  },
};

const $web3Modal = new Web3Modal({
  network: 'mainnet', // optional
  cacheProvider: true, // optional
  providerOptions, // required
});

export default function ({ apolloClient }) {
  return {
    namespaced: true,
    state: () => ({
      isIniting: false,
      isReady: false,
      user: null,
      web3Provider: null,
      pinnedWatchlists: [],
      featureFlags: new Set([]),
      remoteSigningKeysServed: new Set([]),
    }),
    actions: {
      async init({ dispatch, commit }) {
        commit('setIsIniting', true);
        await onLogin(apolloClient);
        const [user] = await Promise.all([
          dispatch('getUser'),
          dispatch('getFeatureFlags'),
        ]).then((res) => res).finally(() => {
          commit('setIsIniting', false);
          commit('setIsReady', true);
        });
        return user;
      },
      async getProvider({ commit, state }) {
        console.log('should get provider');
        if (state.web3Provider) {
          return state.web3Provider;
        }

        const provider = await $web3Modal.connect();
        commit('setWeb3Provider', provider);

        return state.web3Provider;
      },
      async linkWallet({ dispatch }, { provider, force = false }) {
        const web3 = new Web3(provider);

        const [address] = await web3.eth.getAccounts().catch(() => []);

        if (!address) {
          throw new Error('Invalid provider or no connected accounts');
        }

        const { data: { nonce } } = await apolloClient.query({
          client: 'v2',
          fetchPolicy: 'network-only',
          query: GET_NONCE_QUERY,
          variables: {
            id: address,
          },
        });

        const dataToSign = web3.utils.fromUtf8(getMessageToSign(address, nonce));

        const signature = await web3.eth.personal.sign(dataToSign, address);

        const { data, errors } = await apolloClient.mutate({
          mutation: LINK_WALLET_MUTATION,
          variables: {
            id: address,
            signature,
            force,
          },
        });

        if (!data?.linkWallet && errors.length) {
          switch (errors[0].extensions.code) {
            case 'WALLET_ALREADY_LINKED':
              throw new Error('WALLET_ALREADY_LINKED');
            default:
              throw new Error('Failed to link wallet');
          }
        }

        return dispatch('getUser');
      },
      async unlinkWallet({ dispatch }, wallet) {
        await apolloClient.mutate({
          mutation: UNLINK_WALLET_MUTATION,
          variables: {
            id: wallet.id,
          },
        });

        return dispatch('getUser');
      },
      async getUser({ commit, dispatch }) {
        const { data: { user, pinnedWatchlists } } = await apolloClient.query({
          query: GET_CURRENT_USER_QUERY,
          fetchPolicy: 'network-only',
          errorPolicy: 'all',
        }).then((res) => {
          if (!res.data) {
            throw new Error('Failed to fetch user');
          }

          return res;
        }).catch((err) => {
          if (err.networkError?.response?.status === 403) {
            return dispatch('logout');
          }

          throw err;
        });

        if (user === null) {
          return null;
        }

        commit('setUser', user);
        commit('setPinnedWatchlists', pinnedWatchlists);
        dispatch('getFeatureFlags');
        return user;
      },
      async getFeatureFlags({ commit }) {
        const { data: { featureFlags } } = await apolloClient.query({
          query: GET_FEATURE_FLAGS_QUERY,
          fetchPolicy: 'network-only',
          errorPolicy: 'none',
        });

        commit('setFeatureFlags', featureFlags);

        return featureFlags;
      },
      async logout({ commit }) {
        commit('setUser', null);
        commit('setPinnedWatchlists', []);

        $web3Modal.clearCachedProvider();
        localStorage.removeItem('walletconnect');
        navigator.serviceWorker?.controller?.postMessage({ type: 'CLEAR_USER_DATA' });
        const { reset } = useTracking();
        reset();
        await swClearCache();
        nextTick(async () => {
          await onLogout(apolloClient);
        });
      },
      async fetchUserPinnedWatchlists({ commit }) {
        const { data: { pinnedWatchlists } } = await apolloClient.query({
          query: GET_USER_PINNED_WATCHLISTS,
          fetchPolicy: 'network-only',
        });
        commit('setPinnedWatchlists', pinnedWatchlists);
      },
    },
    mutations: {
      setUser(state, user) {
        state.user = user;
        if (user) {
          const { identify } = useTracking();
          identify(user);
        }
      },
      setWeb3Provider(state, provider) {
        state.web3Provider = provider;
      },
      setPinnedWatchlists(state, pinnedWatchlists) {
        state.pinnedWatchlists = pinnedWatchlists;
      },
      setFeatureFlags(state, featureFlags) {
        state.featureFlags = new Set(featureFlags.map((item) => item.name));
      },
      setRemoteSigningKeysServed(state, value) {
        state.remoteSigningKeysServed = value;
      },
      setIsIniting(state, value) {
        state.isIniting = value;
      },
      setIsReady(state, value) {
        state.isReady = value;
      },
    },
    getters: {
      isFeatureFlagEnabled(state) {
        return (featureFlag) => state.featureFlags.has(featureFlag);
      },
      isLoggedIn(state) {
        return state.user !== null;
      },
      isAdmin(state) {
        return state.user?.role === 'ADMIN';
      },
      isPremium(state) {
        return state.user?.isPremium || false;
      },
      wallet(state) {
        return state.user?.wallet || null;
      },
      wallets(state) {
        return state.user?.wallets || [];
      },
      isWalletLinked(state) {
        return (address) => !!state.user?.wallets?.filter((item) => item.id === address?.toLowerCase()).length;
      },
      passBalance(state) {
        return state.user?.passBalance || 0;
      },
      pinnedWatchlists(state) {
        return state.pinnedWatchlists || [];
      },
      automationCreditBalance(state) {
        return state.user?.automationCreditBalance || 0;
      },
      user(state) {
        return state.user;
      },
      isKeyServed(state) {
        return (key) => state.remoteSigningKeysServed.has(key);
      },
      isIniting(state) {
        return state.isIniting;
      },
      isReady(state) {
        return state.isReady;
      },
    },
  };
}
