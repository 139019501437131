import { createClient } from '@reservoir0x/reservoir-sdk';
import { useStore } from 'vuex';
import { ethers } from 'ethers';
import { ref } from 'vue';

const client = createClient({
  chains: [{
    id: 1,
    baseApiUrl: 'https://trade.compass.art/api',
    default: true,
    apiKey: '',
  }],
  source: 'compass.art',
});

export default function useOrderManager() {
  const store = useStore();
  const getSigner = async () => {
    const provider = await store.dispatch('auth/getProvider');
    const ethersProvider = new ethers.providers.Web3Provider(provider);
    return ethersProvider.getSigner();
  };
  const sweepTokens = async (orderIds) => {
    const signer = await getSigner();

    return client.actions.buyToken({
      signer,
      orderIds,
      onProgress: (progress) => {
        // console.log('Progress:', progress);
      },
    });
  };

  const buy = async ({
    orderId, steps, maxFeePerGas, maxPriorityFeePerGas,
  }) => {
    const signer = await getSigner();

    return client.actions.buyToken({
      signer,
      items: [
        {
          orderId,
        },
      ],
      options: maxFeePerGas && maxPriorityFeePerGas ? {
        maxFeePerGas: maxFeePerGas ? ethers.utils.parseUnits(maxFeePerGas, 'gwei').toString() : undefined,
        maxPriorityFeePerGas: maxPriorityFeePerGas ? ethers.utils.parseUnits(maxPriorityFeePerGas, 'gwei').toString() : undefined,
      } : {},
      onProgress: (progress) => {
        steps.value = progress;
      },
    });
  };

  return {
    sweepTokens,
    buy,
  };
}
