import { fromWei } from 'web3-utils';

export function formatWei(input, unit = 'ether') {
  if (!input) {
    return null;
  }
  return parseFloat(
    fromWei(input.toString(), unit),
  );
}
