import { isEqual } from 'lodash-es';
import {
  computed,
  onMounted, reactive, toRefs, watch,
} from 'vue';
import { useRoute, useRouter } from 'vue-router';

const parseValue = ({ type: TypeConstructor }, value) => {
  switch (TypeConstructor) {
    case String:
      return `${value}`;
    case Number:
      return Number(value);
    case Array:
      return Array.from(value);
    case Boolean:
      if (value === 'true') {
        return true;
      }

      if (value === 'false') {
        return false;
      }

      return Boolean(value);
    default:
      console.error(`Unsupported type in useRouteQueryFilters: ${TypeConstructor.name}`);
      return value;
  }
};

export const encodeFilters = (filters) => btoa(JSON.stringify(filters));

/**
 * Sync filters with query string
 * @param {Ref<any[]>} filters Ref object with filters
 * @returns
 */
export function useFiltersQuerySync(filters) {
  const route = useRoute();
  const router = useRouter();

  const queryFilters = computed(() => route.query?.filters);

  const filtersFromQuery = () => {
    // only if empty
    const d = JSON.parse(queryFilters.value ? atob(queryFilters.value) : '[]');
    if (d.length === 0) {
      return;
    }

    if (isEqual(filters.value, d)) {
      return;
    }
    filters.value = d;
  };

  const filtersToQuery = () => {
    if (filters.value.length === 0) {
      router.replace({ query: { filters: undefined } });
    } else {
      router.replace({ query: { filters: encodeFilters(filters.value) } });
    }
  };

  watch(() => filters, () => {
    filtersToQuery();
  }, { deep: true });

  watch(() => queryFilters, () => {
    filtersFromQuery();
  }, { deep: true });

  onMounted(() => {
    filtersFromQuery();
  });

  return {};
}
