import { AUTOMATION_FIELD_FRAGMENT } from '@/graphql/automation/fragments/AutomationFieldsFragment';
import { AUTOMATION_TEMPLATE_FIELD_FRAGMENT } from '@/graphql/automation/fragments/AutomationTemplateFieldsFragment';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed } from 'vue';

const getAutomationTemplatesQuery = gql`
query GetAutomationTemplates {
  automationTemplates {
    ...AutomationTemplateFields
  }
}
${AUTOMATION_TEMPLATE_FIELD_FRAGMENT}
`;

const getAutomationTemplateQuery = gql`
query GetAutomationTemplate($id: Int!) {
  automationTemplate(id: $id) {
    ...AutomationTemplateFields
  }
}
${AUTOMATION_TEMPLATE_FIELD_FRAGMENT}
`;

const createAutomationTemplateMutation = gql`
mutation CreateAutomationTemplate( 
  $trigger: TriggerType!,
  $actions: [ActionConfigInput!]!,
  $name: String!,
  $triggerConfig: JSON,
  $inputs: [ContextVariableInput!]!,
) {
  createAutomationTemplate(
    trigger: $trigger,
    triggerConfig: $triggerConfig,
    actions: $actions,
    name: $name,
    inputs: $inputs,
  ) {
    ...AutomationTemplateFields
  }
}
${AUTOMATION_TEMPLATE_FIELD_FRAGMENT}`;

const createAutomationFromTemplateMutation = gql`
mutation CreateAutomationFromTemplate(
  $templateId: Int!,
  $name: String!,
  $variables: JSON!,
  $status: Boolean!
  $folderId: Int
) {
  createAutomationFromTemplate(
    templateId: $templateId,
    name: $name,
    variables: $variables,
    status: $status,
    folderId: $folderId
  ) {
    ...AutomationFields
  }
}
${AUTOMATION_FIELD_FRAGMENT}
`;

const createAutomationTemplateFromAutomationMutation = gql`
mutation CreateAutomationTemplateFromAutomation(
  $automationId: Int!,
  $name: String!,
) {
  createAutomationTemplateFromAutomation(
    automationId: $automationId,
    name: $name,
  ) {
    ...AutomationTemplateFields
  }
}
${AUTOMATION_TEMPLATE_FIELD_FRAGMENT}
`;

const updateAutomationTemplateMutation = gql`
mutation UpdateAutomationTemplate(
  $id: Int!,
  $trigger: TriggerType,
  $actions: [ActionConfigInput!],
  $name: String,
  $triggerConfig: JSON,
  $inputs: [ContextVariableInput!]!,
) {
  updateAutomationTemplate(
    id: $id,
    trigger: $trigger,
    triggerConfig: $triggerConfig,
    actions: $actions,
    name: $name,
    inputs: $inputs,
  ) {
    ...AutomationTemplateFields
  }
}
${AUTOMATION_TEMPLATE_FIELD_FRAGMENT}`;

const deleteAutomationTemplateMutation = gql`
mutation DeleteAutomationTemplate($id: Int!) {
  deleteAutomationTemplate(id: $id)
}
`;

const publishAutomationTemplateMutation = gql`
mutation PublishAutomationTemplate($id: Int!, $name: String!, $description: String!, $shortDescription: String!, $tagIds: [Int!]!) {
  publishAutomationTemplate(id: $id, name: $name, description: $description, shortDescription: $shortDescription, tagIds: $tagIds) {
    id
  }
}
`;

export default function useAutomationTemplates() {
  const {
    result,
    loading,
  } = useQuery(getAutomationTemplatesQuery);

  const { mutate: create } = useMutation(createAutomationTemplateMutation, () => ({
    updateQueries: {
      GetAutomationTemplates: (prev, { mutationResult: { data: { createAutomationTemplate } } }) => {
        const data = JSON.parse(JSON.stringify(prev));
        data.automationTemplates = [
          createAutomationTemplate,
          ...data.automationTemplates,
        ];
        return data;
      },
    },
  }));

  const { mutate: createAutomation } = useMutation(createAutomationFromTemplateMutation, () => ({
    updateQueries: {
      GetAutomations: (prev, { mutationResult: { data: { createAutomationFromTemplate } } }) => {
        const data = JSON.parse(JSON.stringify(prev));
        data.automations = [
          createAutomationFromTemplate,
          ...data.automations,
        ];
        return data;
      },
    },
  }));

  const { mutate: publishAutomationTemplate, loading: isPublishing } = useMutation(publishAutomationTemplateMutation, () => ({
    errorPolicy: 'none',
  }));

  const { mutate: createTemplateFromAutomation } = useMutation(createAutomationTemplateFromAutomationMutation, () => ({
    updateQueries: {
      GetAutomationTemplates: (prev, { mutationResult: { data: { createAutomationTemplateFromAutomation } } }) => {
        const data = JSON.parse(JSON.stringify(prev));
        data.automationTemplates = [
          createAutomationTemplateFromAutomation,
          ...data.automationTemplates,
        ];
        return data;
      },
    },
  }));

  const { mutate: update } = useMutation(updateAutomationTemplateMutation, () => ({
    updateQueries: {
      GetAutomationTemplates: (prev, next) => {
        const { mutationResult: { data: { updateAutomationTemplate } } } = next;
        const data = JSON.parse(JSON.stringify(prev));
        const index = data.automationTemplates.findIndex((item) => item.id === updateAutomationTemplate.id);
        data.automationTemplates.splice(index, 1, {
          ...data.automationTemplates[index],
          ...updateAutomationTemplate,
        });
        return data;
      },
    },
  }));

  const { mutate: deleteMutation } = useMutation(deleteAutomationTemplateMutation);

  const getAutomationTemplate = (id) => {
    const {
      result: automationTemplateResult, onResult, loading: isLoading,
    } = useQuery(getAutomationTemplateQuery, () => ({ id: Number(id.value) }));

    return {
      automationTemplate: computed(() => automationTemplateResult.value?.automationTemplate),
      onResult,
      isLoading,
    };
  };

  const automationTemplates = computed(() => result.value?.automationTemplates || []);

  return {
    automationTemplates,
    isLoading: loading,
    create,
    update,
    deleteMutation: (variables, options = {}) => deleteMutation(variables, {
      ...options,
      updateQueries: {
        GetAutomationTemplates: (prev, next) => {
          const { mutationResult: { data: { deleteAutomationTemplate } } } = next;
          if (!deleteAutomationTemplate) {
            return prev;
          }

          const data = JSON.parse(JSON.stringify(prev));

          data.automationTemplates = data.automationTemplates.filter((item) => item.id !== variables.id);
          return data;
        },
      },

    }),
    getAutomationTemplate,
    createAutomation,
    createTemplateFromAutomation,
    publishAutomationTemplate,
    isPublishing,
  };
}
