import gql from 'graphql-tag';
import collectionWatchlistFields from '@/graphql/watchlists/fragments/collectionWatchlistFields.fragment.gql';
import { COLLECTION_LIST_ITEM_FIELDS } from '@/graphql/collection/fragments/collectionListItemFields.fragment';

export const GET_COLLECTION_WATCHLIST_BY_SLUG_QUERY = gql`
  query GetCollectionWatchlistBySlug($slug: String!) {
    watchlist: collectionWatchlistBySlug(slug: $slug) {
      ...CollectionWatchlist_Fields
      id
      isPinned
      memberCount
      members {
        ...CollectionListItem_Fields
      }
    }
  }
  ${COLLECTION_LIST_ITEM_FIELDS}
  ${collectionWatchlistFields}
`;
