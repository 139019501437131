import gql from 'graphql-tag';
import { ACTION_INPUT_FIELD_FRAGMENT } from '@/graphql/automation/fragments/InputFieldsFragment';

export const AUTOMATION_FIELD_FRAGMENT = gql`
fragment AutomationFields on Automation {
  id
  name
  status
  trigger
  triggerConfig
  actions
  inputs
  runCount
  createdAt
  updatedAt
  folderId
  lastRun {
    id
    status
    triggeredAt
  }
  inputsSchema {
    ...ActionInput_fields
  }
}
${ACTION_INPUT_FIELD_FRAGMENT}
`;
