import { COLLECTION_WITH_STATS_FIELDS } from '@/graphql/collection/fragments/collectionWithStatsFields.fragment';
import gql from 'graphql-tag';

const userFavoriteCollectionsQuery = gql`
query GetUserFavoriteCollections($period: String, $sortBy: String, $sortDesc: Boolean) {
  collections: favoriteCollections(period: $period, sortBy: $sortBy, sortDesc: $sortDesc) {
    ...CollectionWithStats_Fields
  }
}
${COLLECTION_WITH_STATS_FIELDS}
`;

export default userFavoriteCollectionsQuery;
