import { omit } from 'lodash-es';
import mixpanel from 'mixpanel-browser';

mixpanel.init('74b90f0dfe53df34e5a0372f33021fd0', { debug: process.env.NODE_ENV !== 'production' });

export default function useTracking() {
  const track = (...args) => {
    mixpanel.track(...args);
  };

  const setAttributes = (attributes) => {
    mixpanel.people.set(attributes);
  };

  const identify = (user) => {
    mixpanel.identify(user.id);
    setAttributes(user);
  };

  const reset = () => {
    mixpanel.reset();
  };

  return {
    track,
    setAttributes,
    identify,
    reset,
  };
}
