import gql from 'graphql-tag';

export const UPDATE_COLLECTION_WATCHLIST_MUTATION = gql`
mutation UpdateWatchlist($id: Int!, $name: String!) {
  updateCollectionWatchlist(id: $id, name: $name) {
    id
  }
}
`;

export const UPDATE_WALLET_WATCHLIST_MUTATION = gql`
mutation UpdateWalletWatchlist($id: Int!, $name: String, $status: WatchlistStatus) {
  updateWalletWatchlist(id: $id, name: $name, status: $status) {
    id
  }
}
`;
