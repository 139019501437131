import gql from 'graphql-tag';

export const ADD_COLLECTION_TO_WATCHLIST = gql`
mutation AddCollectionsToWatchlist($id: Int!, $modelIds: [String!]!) {
  addCollectionsToWatchlist(watchlistId: $id, collectionIds: $modelIds) {
    id
    memberCount
  }
}
`;

export const ADD_WALLET_TO_WATCHLIST = gql`
mutation AddWalletsToWatchlist($id: Int!, $modelIds: [String!]!) {
  addWalletsToWatchlist(watchlistId: $id, walletIds: $modelIds) {
    id
    memberCount
  }
}
`;
