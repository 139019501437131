import apolloClients from '@/vue-apollo';
import { io } from 'socket.io-client';
import gql from 'graphql-tag';
import { pick, cloneDeep } from 'lodash-es';
import mitt from 'mitt';

export const socket = io('https://realtime.compass.art/', {
  forceNew: true,
  autoConnect: true,
});

export const eventBus = mitt();

socket.on('connect', () => {
  console.log('Connected to realtime server');
});

export const onAuth = (token) => {
  socket.auth = { token };
  if (socket.connected) {
    socket.disconnect();
  }
  socket.connect();
};

export default function setupRealTime({ store }) {
  const { cache } = apolloClients.default;
  // socket.on('collection:update', (payload) => {
  //   const keys = Object.keys(payload);
  //   cache.writeFragment({
  //     id: `Collection:${payload.id}`,
  //     fragment: gql`
  //     fragment CollectionRealtimeFields on Collection {
  //       ${keys.join('\n')}
  //     }
  //   `,
  //     data: payload,
  //   });
  // });

  socket.on('user:update', (payload) => {
    store.commit('auth/setUser', {
      ...store.getters['auth/user'],
      ...payload,
    });
  });

  socket.on('automation:update', (payload) => {
    const keys = Object.keys(payload);
    cache.updateFragment({
      id: `Automation:${payload.id}`,
      fragment: gql`
      fragment CollectionRealtimeFields on Automation {
        ${keys.join('\n')}
      }
    `,
    }, (existing) => {
      if (existing) {
        return {
          ...cloneDeep(existing),
          ...payload,
        };
      }
      return null;
    });
  });

  socket.on('automationRun:update', (payload) => {
    const keys = Object.keys(payload);

    if (payload.status !== 'running') {
      eventBus.emit(`automationRun:complete:${payload.id}`, payload.status);
    }

    cache.updateFragment({
      id: `AutomationRun:${payload.id}`,
      fragment: gql`
      fragment AutomationRun_UpdatedRunTime on AutomationRun {
        ${keys.join('\n')}
      }
    `,
    // data: payload,
    }, (existing) => cloneDeep({
      ...existing,
      ...payload,
      runTime: payload.finishedAt ? new Date(payload.finishedAt).getTime() - new Date(payload.startedAt).getTime() : null,
    }));

    cache.updateFragment({
      id: `Automation:${payload.automationId}`,
      fragment: gql`
      fragment Automation_UpdatedLastRun on Automation {
        lastRun {
          ${keys.join('\n')}
        }
      }
    `,
    // data: payload,
    }, (existing) => {
      if (!existing) {
        return existing;
      }

      if (existing.lastRun?.id === payload.id) {
        return cloneDeep({
          lastRun: {
            ...existing.lastRun,
            ...payload,
          },
        });
      }

      return existing;
    });
  });

  socket.on('automationRun:create', (payload) => {
    const keys = Object.keys(payload);
    cache.writeFragment({
      id: `Automation:${payload.automationId}`,
      fragment: gql`
      fragment Automation_UpdatedLastRunData on Automation {
        lastRun {
          id
          status
          triggeredAt
        }
      }
    `,
      data: {
        lastRun: pick(payload, ['id', 'status', 'triggeredAt']),
      },
    });

    cache.writeFragment({
      id: `AutomationRun:${payload.id}`,
      fragment: gql`
      fragment AutomationRun_UpdatedFields on AutomationRun {
        ${keys.join('\n')}
      }
    `,
      data: payload,
    });

    cache.updateFragment({
      id: `Automation:${payload.automationId}`,
      fragment: gql`
      fragment Automation_UpdatedRuns on Automation {
        runs {
          id
          startedAt
          status
          finishedAt
          triggeredAt
          runTime
          error
        }
      }
    `,
    }, (existing) => {
      if (!existing) {
        return existing;
      }

      const data = pick(payload, ['id', 'startedAt', 'status', 'finishedAt', 'triggeredAt', 'runTime', 'error']);
      return cloneDeep({
        runs: [{
          __typename: 'AutomationRun',
          ...data,
          runTime: null,
        }, ...(existing.runs || [])],
      });
    });
  });
}
