import collectionWatchlistFields from '@/graphql/watchlists/fragments/collectionWatchlistFields.fragment.gql';
import walletWatchlistFields from '@/graphql/watchlists/fragments/walletWatchlistFields.fragment.gql';
import gql from 'graphql-tag';

export const GET_COLLECTION_WATCHLISTS_QUERY = gql`
query GetCollectionWatchlists {
  watchlists: collectionWatchlists {
    ...CollectionWatchlist_Fields
    volumeOverTime(period: "30d") {
      key
      value
    }
    volume {
      value
      previous
    }
    marketCap {
      value
      previous
    }
    members {
      id
    }
  }
}
${collectionWatchlistFields}
`;

export const GET_WALLET_WATCHLISTS_QUERY = gql`
query GetWalletWatchlists {
  watchlists: walletWatchlists {
    ...WalletWatchlist_Fields
  }
}
${walletWatchlistFields}
`;
