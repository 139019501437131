import { COLLECTION_LIST_ITEM_FIELDS } from '@/graphql/collection/fragments/collectionListItemFields.fragment';
import { TOKEN_FIELDS } from '@/graphql/token/fragments/tokenFields.fragment';
import { WALLET_FIELDS } from '@/graphql/wallet/fragments/walletFields.fragment';
import gql from 'graphql-tag';

export const ACTIVITY_ITEM_FIELDS_FRAGMENT = gql`
fragment ActivityItemFields on ActivityItem {
  id
  timestamp
  transaction {
    id
  }
  collection {
    ...CollectionListItem_Fields
    liquidityRank
  }
  token {
    ...Token_Fields
  }
  from {
    ...Wallet_Fields
  }
  to {
    ...Wallet_Fields
  }
  sale {
    marketplace
    type
  }
  listing {
    marketplace
  }
  offer {
    marketplace
  }
  value
  floorDelta
  gasSpent
  type
}
${WALLET_FIELDS}
${TOKEN_FIELDS}
${COLLECTION_LIST_ITEM_FIELDS}
`;
