const callbacks = [];

document.addEventListener('click', (e) => {
  callbacks.forEach((cb) => {
    cb(e);
  });
});

const onDocumentClick = (cb) => {
  callbacks.push(cb);
};

export default function useClickAwaySimple(el) {
  const onClickAway = (cb) => {
    onDocumentClick((clickEvent) => {
      if (!el.contains(clickEvent.target) && el !== clickEvent.target) {
        cb(clickEvent);
      }
    });
  };
  return {
    onClickAway,
  };
}
