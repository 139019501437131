export default function ipfsUrl(url) {
  const parsed = url.replace('ipfs://ipfs/', 'ipfs://').replace('ipfs://', 'https://ipfs.compass.art/ipfs/');

  // eslint-disable-next-line no-unused-vars
  const [host, path] = parsed.split('/ipfs/');
  if (!path) {
    return url;
  }

  return `https://ipfs.compass.art/ipfs/${path}`;
}
