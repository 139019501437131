import gql from 'graphql-tag';
import collectionWatchlistFields from '@/graphql/watchlists/fragments/collectionWatchlistFields.fragment.gql';
import walletWatchlistFields from '@/graphql/watchlists/fragments/walletWatchlistFields.fragment.gql';

export const GET_COLLECTION_WATCHLISTS_WITH_MEMBERSHIPS = gql`
query GetCollectionWatchlistsWithMemberCheck($id: String!) {
  watchlists: collectionWatchlists {
    ...CollectionWatchlist_Fields
    isMember(id: $id)
  }
}
${collectionWatchlistFields}
`;

export const GET_WALLET_WATCHLISTS_WITH_MEMBERSHIPS = gql`
query GetWalletWatchlistsWithMemberCheck($id: String!) {
  watchlists: walletWatchlists {
    ...WalletWatchlist_Fields
    isMember(id: $id)
  }
}
${walletWatchlistFields}
`;
