import { useQuery } from '@vue/apollo-composable';
import collectionAttributesQuery from '@/graphql/collection/queries/collectionAttributes.query.gql';
import { computed } from 'vue';

export default function useTraits(collectionId) {
  const { result, loading } = useQuery(collectionAttributesQuery, () => ({ collectionAddress: collectionId.value }), () => ({
    enabled: computed(() => !!collectionId.value),
  }));

  const traits = computed(() => result.value?.collection?.attributes || []);

  return {
    traits,
    loading,
  };
}
