/* eslint-disable no-param-reassign */
import { takeWhile } from 'lodash-es';
import { nFormatter } from './nFormatter';

export function formatNumber(value, decimals = 2, nify = true) {
  if (Math.abs(value) > 10000 && nify) {
    return nFormatter(value, decimals);
  }

  if (typeof value === 'string') {
    value = parseFloat(value);
  }

  if (typeof value === 'undefined' || value === null || typeof value !== 'number' || Number.isNaN(value)) {
    return '--';
  }

  // eslint-disable-next-line no-unused-vars
  const [beforeDecimals, afterDecimals] = value.toString().split('.');
  const leadingZeroCount = Math.min(takeWhile(afterDecimals || [], (c) => c === '0').length, 10);

  return value.toLocaleString(
    undefined,
    {
      minimumFractionDigits: leadingZeroCount,
      maximumFractionDigits: leadingZeroCount + decimals,
    },
  );
}
