import { WALLET_FIELDS } from '@/graphql/wallet/fragments/walletFields.fragment';
import { useMutation, useQuery } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { computed } from 'vue';

const getQuery = gql`
query GetPrivateKeys {
  automationWallets {
    id
    address
    name
    type
    isOnline
    wallet {
      ...Wallet_Fields
      ethBalance
      wethBalance: erc20Balance(contract: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2")
      bethBalance: erc20Balance(contract: "0x0000000000a39bb272e79075ade125fd351887ac")
    }
  }
}
${WALLET_FIELDS}
`;

const createMutation = gql`
mutation CreatePrivateKey(
  $name: String!
  $token: String
  $type: PrivateKeyType
) {
  createAutomationWallet(
    name: $name,
    token: $token,
    type: $type
  ) {
    id
    address
    name
    type
    isOnline
    wallet {
      ...Wallet_Fields
      ethBalance
      wethBalance: erc20Balance(contract: "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2")
      bethBalance: erc20Balance(contract: "0x0000000000a39bb272e79075ade125fd351887ac")
    }
  }
}
${WALLET_FIELDS}`;

const exportMutation = gql`
mutation CreatePrivateKey(
  $id: Int!
) {
  privateKey: exportPrivateKey(
    id: $id
  )
}`;

export default function useAutomationWallets() {
  const {
    result,
    loading,
  } = useQuery(getQuery, () => ({}), () => ({
    pollInterval: 15000,
  }));

  const automationWallets = computed(() => result.value?.automationWallets || []);

  const { mutate: create } = useMutation(createMutation, () => ({
    updateQueries: {
      GetPrivateKeys: (prev, { mutationResult: { data: { createAutomationWallet } } }) => {
        const data = JSON.parse(JSON.stringify(prev));
        data.automationWallets = [
          createAutomationWallet,
          ...data.automationWallets,
        ];
        return data;
      },
    },
  }));

  const { mutate: exportPrivateKey } = useMutation(exportMutation, () => ({}));

  return {
    automationWallets,
    isLoading: loading,
    create,
    exportPrivateKey,
  };
}
