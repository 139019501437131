import { nextTick, ref } from 'vue';

const isVisible = ref(false);
const tokenId = ref(null);
const collection = ref(null);
const tokenModal = ref(null);

export default function useTokenModal() {
  const open = (id, { accordion = null, buyIntent = false } = {}) => {
    // eslint-disable-next-line prefer-destructuring
    tokenId.value = id.split('_')[1];
    collection.value = { id: id.split('_')[0] };
    isVisible.value = true;
    nextTick(() => {
      tokenModal.value.setConfig({ accordion, buyIntent });
    });
  };

  const close = () => {
    isVisible.value = false;
  };

  return {
    isVisible,
    open,
    close,
    collection,
    tokenModal,
    tokenId,
  };
}
