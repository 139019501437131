import { useStore } from 'vuex';
import { computed } from 'vue';

export default function useSettings(settingsToMap) {
  const store = useStore();
  const results = {};
  Object.keys(settingsToMap).forEach((componentField) => {
    let fieldData = settingsToMap[componentField];

    if (typeof fieldData !== 'object') {
      fieldData = {
        key: fieldData,
        default: undefined,
      };
    }

    function getKey() {
      let setting = fieldData.key;
      if (typeof setting === 'function') {
        setting = setting.bind(this)();
      }

      return setting;
    }

    results[componentField] = computed({
      get() {
        const value = store.getters['settings/getValue'](getKey());

        if (typeof value === 'undefined' && typeof fieldData.default === 'function') {
          return fieldData.default.bind(this)();
        }

        if (typeof value === 'undefined') {
          return fieldData.default;
        }

        return value;
      },
      set(value) {
        store.dispatch('settings/setValue', { key: getKey(), value });
      },
    });
  });

  return results;
}
