/* eslint-disable no-param-reassign */
import { formatNumber } from './formatNumber';

export function formatPercent(value, precision = 2) {
  if (value === null || Number.isNaN(value)) {
    return '--';
  }

  if (value * 100 < 1) {
    precision = Math.max(precision, 2);
  }

  if (value === Infinity) {
    return '∞';
  }

  value = parseFloat(value * 100);

  if (value > 1000) {
    return `${formatNumber(value)}%`;
  }

  return `${value.toFixed(precision)}%`;
}
