import {
  inject,
  ref,
  watch,
  effectScope,
  onScopeDispose,
  nextTick,
} from 'vue';
import { useGlobalQueryLoading } from '@vue/apollo-composable';
import { useUiDialog } from '@/components/ui-dialog/useUiDialog';

function sleep(sleepFor) {
  return (new Promise((resolve) => setTimeout(resolve, sleepFor)));
}

function refetchQueries(refetchDict, ethBlockHeadRef) {
  const globalLoading = useGlobalQueryLoading();
  const isRefetching = ref(false);
  const isRefetchDisabled = ref(false);
  const lastRefetch = ref(null);
  const { info } = useUiDialog();

  // eslint-disable-next-line no-underscore-dangle
  async function _onNewBlock() {
    if (globalLoading.value) {
      return;
    }

    if (isRefetching.value) {
      return;
    }

    if (isRefetchDisabled.value) {
      return;
    }

    if (lastRefetch.value !== null && document.visibilityState !== 'visible' && (Date.now() - lastRefetch.value) < (2 * 60 * 1000)) {
      return;
    }

    if (lastRefetch.value !== null && (Date.now() - lastRefetch.value) < (15 * 1000)) {
      return;
    }

    isRefetching.value = true;
    await Promise.all(
      // We randomly delay refetches up to 2 seconds
      // for better load distribution
      Object.values(refetchDict).map((fn) => fn()),
    ).finally(() => {
      isRefetching.value = false;
      lastRefetch.value = Date.now();
    });
  }

  watch(() => ethBlockHeadRef.value, _onNewBlock);

  // When tab is visible again we reset last refetch
  // so all data is updated when the tab is back in view.
  // This call is safe when going to background
  // since _onNewBlock checks if the tab is visible
  const onVisibilityChange = () => {
    if (document.visibilityState === 'visible') {
      lastRefetch.value = Math.max(Date.now() - 16 * 1000, lastRefetch.value);
    }

    nextTick(() => {
      _onNewBlock();
    });
  };

  window.addEventListener('visibilitychange', onVisibilityChange);

  onScopeDispose(() => {
    window.removeEventListener('visibilitychange', onVisibilityChange);
  });

  function disableRefetch() {
    if (isRefetchDisabled.value) {
      return;
    }

    isRefetchDisabled.value = true;
    info('Realtime refresh disabled for current view. Refresh the page to have the data auto-update again.', {
      icon: 'far fa-exclamation-triangle text-yellow-400 text-xl',
    });
  }

  function enableRefetch() {
    if (!isRefetchDisabled.value) {
      return;
    }
    isRefetchDisabled.value = true;
  }

  return {
    isRefetching,
    isRefetchDisabled,
    enableRefetch,
    disableRefetch,
  };
}

// before dive into, read https://github.com/vuejs/rfcs/blob/master/active-rfcs/0041-reactivity-effect-scope.md
// this approach guarantee that there is only one instance of *refetchQueries* function, i.e. only one watcher,
// only one window listener etc. We only add refetch functions to the dictionary - *refetchDict*
function createSharableRefetcher() {
  let scope; let
    state;
  const $refetchDict = {};

  const dispose = (keys) => {
    Object.keys(keys).forEach((key) => {
      delete $refetchDict[key];
    });

    // if there are no refetch functions to run, we will remove *refetchQueries* function from memory, i.e. dispose it
    if (state && Object.keys($refetchDict).length === 0) {
      scope.stop();
      scope = null;
      state = null;
    }
  };

  return (refetchDict = {}) => {
    for (const [key, refetchFn] of Object.entries(refetchDict)) {
      $refetchDict[key] = refetchFn;
    }

    // if it is first run, we create scope for *refetchQueries* function
    if (!state) {
      const ethBlockHead = inject('eth:blockHead');
      scope = effectScope(true);
      state = scope.run(() => refetchQueries($refetchDict, ethBlockHead));
    }

    // if component is unmounted, i.e. its scope is disposed, check if there are refetch function in the *$refetchDict*
    // and if there aren't, dispose *refetchQueries* as well
    onScopeDispose(() => dispose(Object.keys(refetchDict)));
    return state;
  };
}

export const useRefetchQueries = createSharableRefetcher();
