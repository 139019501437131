import { createStore } from 'vuex';
import auth from './modules/auth';
import gas from './modules/gas';
import settings from './modules/settings';

export default function ({ apolloClient }) {
  return createStore({
    state: {
      isLoading: false,
      isLoggedIn: false,
      isConnected: false,
      passBalance: 0,
      isDiscordLinked: false,
      premiumExpiresAt: null,
      isPremium: process.env.VUE_APP_IS_PREMIUM_DEFAULT === 'true',
      user: {
        id: null,
      },
    },
    getters: {
      isPremium(state) {
        return state.isPremium;
      },
      isLoading(state) {
        return state.isLoading;
      },
    },
    mutations: {
      setUser(state, {
        user, isLoggedIn = false, isPremium = false, isDiscordLinked = false, passBalance = 0, premiumExpiresAt = null,
      }) {
        state.user = user;
        state.isConnected = !!user.id;
        state.isLoggedIn = isLoggedIn;
        state.isPremium = isPremium;
        state.isDiscordLinked = isDiscordLinked;
        state.passBalance = passBalance;
        state.premiumExpiresAt = premiumExpiresAt;
      },
      setIsLoggedIn(state, value) {
        state.isLoggedIn = value;
      },
      setLoading(state, loading) {
        state.isLoading = loading;
      },
    },
    actions: {
      checkAuth({ commit }) {
        commit('setIsLoggedIn', false);
      },
    },
    modules: {
      auth: auth({ apolloClient }),
      settings: settings(),
      gas: gas(),
    },
  });
}
