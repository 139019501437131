import { z } from 'zod';

const constrainByActingAsField = (schema, actingAs) => {
  switch (actingAs) {
    case 'collection':
    case 'wallet':
    case 'tradingWallet':
      return schema.refine((v) => v?.startsWith('0x'), {
        message: 'Invalid collection address',
      });
    default:
      return schema;
  }
};

export default function getSchema(inputs) {
  const schema = {};
  inputs.forEach((input) => {
    let itemSchema = z.any().nullish();
    switch (input.type) {
      case 'String':
        itemSchema = z.string();
        break;
      case 'Number':
        itemSchema = z.preprocess((v) => Number(v), z.number({
          invalid_type_error: 'Must be a number',
        }));
        break;
      case 'Boolean':
        itemSchema = z.boolean();
        break;
      case 'Object':
        itemSchema = z.object({});
        break;
      case 'Array':
        itemSchema = z.array(z.any());
        break;
      default:
        break;
    }

    if (input.actingAs) {
      itemSchema = constrainByActingAsField(itemSchema, input.actingAs);
    }

    if (!input.required) {
      itemSchema = itemSchema.nullable().optional();
    }

    schema[input.name] = itemSchema;
  });

  return z.object(schema);
}
