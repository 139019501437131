import gql from 'graphql-tag';
import { COLLECTION_WITH_STATS_FIELDS } from '@/graphql/collection/fragments/collectionWithStatsFields.fragment';

export const GET_WATCHLIST_COLLECTION_STATS_QUERY = gql`
  query GetWatchlistCollectionStats($watchlistId: Int!, $period: String, $sortBy: String, $sortDesc: Boolean, $take: Int, $skip: Int) {
    collections: watchlistCollectionsSales(watchlistId: $watchlistId, period: $period, sortBy: $sortBy, sortDesc: $sortDesc, take: $take, skip: $skip) {
      ...CollectionWithStats_Fields
    }
  }
  ${COLLECTION_WITH_STATS_FIELDS}
`;
