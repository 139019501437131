export const ENS_ADDRESS = '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85';

export const EVENT_TYPES = {
  SELL: 'sell',
  BUY: 'buy',
  SEND: 'send',
  RECEIVE: 'receive',
  MINT: 'mint',
  BURN: 'burn',
  LIST: 'list',
};

export const WATCHLIST_TYPES = {
  COLLECTION: 'COLLECTION',
  WALLET: 'WALLET',
};
