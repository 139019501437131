import { useQuery } from '@vue/apollo-composable';
import recommendedCollectionsQuery from '@/graphql/collection/queries/recommendedCollections.query.gql';
import { computed, unref } from 'vue';

export function useRecommendedCollections({ enabled }) {
  const {
    result,
    loading,
  } = useQuery(
    recommendedCollectionsQuery,
    {},
    () => ({
      enabled: unref(enabled),
    }),
  );

  return {
    collections: computed(() => result.value?.collections || []),
    isLoading: loading,
  };
}
