import gql from 'graphql-tag';
import { ACTION_INPUT_FIELD_FRAGMENT } from './InputFieldsFragment';

export const AUTOMATION_TEMPLATE_FIELD_FRAGMENT = gql`
fragment AutomationTemplateFields on AutomationTemplate {
  id
  name  
  trigger
  triggerConfig
  actions
  inputs {
    ...ActionInput_fields
  }
  createdAt
  updatedAt
}
${ACTION_INPUT_FIELD_FRAGMENT}
`;
