/* eslint-disable no-alert */
import { Workbox } from 'workbox-window';
import { useReloadNotification } from '@/components/ReloadNotification.vue';

const scriptLoadingTimestamp = Date.now();
const environment = process.env.NODE_ENV;
let broadcast;
export const getBroadcastChannel = () => broadcast;
export const canRegisterSW = true || (environment === 'production' && ('serviceWorker' in navigator));

export async function swClearCache() {
  if (canRegisterSW) {
    getBroadcastChannel().postMessage({
      type: 'CLEAR_CACHE',
      payload: {
        names: ['user-data'],
      },
    });
  }
}

async function registerSW() {
  if (!canRegisterSW) return;

  broadcast = new BroadcastChannel('compass');
  const baseUrl = process.env.BASE_URL;
  const wb = new Workbox(`${baseUrl}sw.js`);

  const showSkipWaitingPrompt = async () => {
    const { setResolve } = useReloadNotification();

    wb.addEventListener('controlling', () => {
      window.location.reload();
    });

    const updateTab = await (new Promise((r) => {
      setResolve(r);
    }));

    if (updateTab) {
      wb.messageSkipWaiting();
    }
  };

  wb.addEventListener('waiting', (event) => {
    const newSwWaitingTimestamp = Date.now();
    // ten minutes after page loading
    if ((newSwWaitingTimestamp - scriptLoadingTimestamp) < (1000 * 60 * 10)) {
      wb.messageSkipWaiting();
    } else {
      showSkipWaitingPrompt(event);
    }
  });

  try {
    await wb.register({ immediate: true });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('SW registration failed\n', error);
  }
}

registerSW();
