let callbacks = [];
let interval = null;

export function subscribe(cb) {
  callbacks.push(cb);
  if (!interval) {
    interval = setInterval(() => {
      callbacks.forEach((item) => {
        if (typeof item !== 'function') {
          return;
        }

        item();
      });
    }, 200);
  }
}

export function unsubscribe(cb) {
  callbacks = callbacks.filter((item) => item !== cb);
  if (callbacks.length === 0) {
    clearInterval(interval);
    interval = null;
  }
}
