import { formatNumber } from './formatNumber';

export function formatCurrency(input, currency = 'ETH', decimals = 3, addSymbol = true) {
  let computedDecimals = decimals;
  if (input > 1000 || input < -1000) {
    computedDecimals = 0;
  }

  if (input > 100 || input < -100) {
    computedDecimals = 1;
  }

  if (input > 10 || input < -10) {
    computedDecimals = 2;
  }

  if (currency === 'ETH') {
    return `${addSymbol ? 'Ξ ' : ''}${formatNumber(input, computedDecimals)}`;
  }

  if (currency === 'SOL') {
    return `${addSymbol ? '◎ ' : ''}${formatNumber(input, computedDecimals)}`;
  }

  if (currency === 'USD') {
    return `${addSymbol ? '$ ' : ''}${formatNumber(input, computedDecimals)}`;
  }

  return formatNumber(input, computedDecimals);
}
