import {
  createHttpLink,
  InMemoryCache,
  from,
  split,
} from '@apollo/client/core';
import { createPersistedQueryLink } from '@apollo/client/link/persisted-queries';
import { sha256 } from 'crypto-hash';
import { getMainDefinition } from '@apollo/client/utilities';
import createWsLink from '@/vue-apollo/links/ws';
import { onError } from '@apollo/client/link/error';
import { useApolloErrors } from '@/composition/errors';

const { onApolloError } = useApolloErrors();

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach((item) => onApolloError(item));
  }
  if (networkError) {
    // eslint-disable-next-line no-console
    console.log(`[Network error]: ${networkError}`);
  }
});

const v2HttpLink = createHttpLink({
  uri: process.env.VUE_APP_GRAPHQL_HTTP_V2
  // || "http://localhost:3000/graphql"
  || 'https://api.compass.art/graphql',
  useGETForQueries: true,
  credentials: 'include',
});

// const v2HttpLink = new BatchHttpLink({
//   uri: process.env.VUE_APP_GRAPHQL_HTTP_V2
//   // || "http://localhost:3000/graphql"
//   || 'https://api.compass.art/graphql',
//   useGETForQueries: true,
//   batchMax: 10, // No more than 5 operations per batch
//   batchInterval: 200, // Wait no more than 20ms after first batched operation
// });

const persistedQueriesLink = createPersistedQueryLink({ sha256 });
const wsEndpoint = process.env.VUE_APP_GRAPHQL_WS_V2 || 'wss://api.compass.art/graphql-socket';

const wsLink = createWsLink({ wsEndpoint });

const v2ClientOptions = {
  name: 'web',

  version: process.env.VUE_APP_VERSION,

  link: from([
    errorLink,

    split(
      ({ query }) => {
        const { kind, operation } = getMainDefinition(query);
        return kind === 'OperationDefinition' && operation === 'subscription';
      },

      wsLink,

      from([
        persistedQueriesLink,
        v2HttpLink,
      ]),
    ),
  ]),

  cache: new InMemoryCache({
    possibleTypes: {
      Prize: ['TokenPrize', 'WhitelistCollectionPrize'],
      IAutomationTemplate: ['AutomationTemplate', 'PublicTemplate'],
    },
    typePolicies: {
      Collection: {
        merge(existing, incoming) {
          if (!incoming && existing) {
            return existing;
          }
          return incoming;
        },
        fields: {
          listingEvents: {
            merge(_, incoming) {
              return incoming;
            },
          },
          transactionsChart: {
            merge(existing, incoming) {
              if (!incoming && existing) {
                return existing;
              }
              return incoming;
            },
          },
        },
      },
      CollectionCharts: {
        merge: true,
      },
      Subscription: {
        fields: {
          collectionListingsFeed: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Query: {
        fields: {
          automations: {
            merge(existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  }),

  defaultOptions: {
    query: {
      errorPolicy: 'all',
    },

    watchQuery: {
      errorPolicy: 'all',
    },

    mutate: {
      errorPolicy: 'all',
    },
  },
};

export default v2ClientOptions;
