/* eslint-disable consistent-return */
import {
  createApp, h, nextTick, provide,
} from 'vue';
import { createMetaManager, plugin as metaPlugin } from 'vue-meta';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './assets/css/style.scss';
import '@/components/collections/workers/token-price-over-time-chart';
import '@/components/workers/tradingview-chart';
import moment from 'moment-timezone';
import VueGtag from 'vue-gtag';
import VueScrollTo from 'vue-scrollto';
import VueGridLayout from 'vue-grid-layout';
import { onLogin } from '@/vue-apollo/controllers';
import { getBroadcastChannel, canRegisterSW } from '@/registerSW';
import { ApolloClients } from '@vue/apollo-composable';
import Vue3TouchEvents from 'vue3-touch-events';
import eventBus from '@/utils/event-bus';
import {
  tooltip,
} from '@/utils/directives';
import NProgress from 'nprogress';
import cronLight from '@vue-js-cron/core';
import router from './router';
import apolloClients from './vue-apollo';
import createStore from './store';
import App from './App.vue';
import { autofocus } from './utils/directives/autofocus';
import '@vue-js-cron/light/dist/light.css';
import setupRealTime from './real-time';

import { init as initRemoteSigning } from './remote-signing';
import { get, init as initRemoteSigningStorage } from './remote-signing/storage';
import { usePremium } from './composition/premium';

const DEFAULT_MOMENT_FORMAT = 'YYYY-MM-DD HH:mm:ss';
moment.defaultFormat = DEFAULT_MOMENT_FORMAT;
moment.utc.defaultFormat = DEFAULT_MOMENT_FORMAT;
moment.tz.setDefault('UTC');

const store = createStore({ apolloClient: apolloClients.default });
const metaManager = createMetaManager();

// Handle revalidated response from sw's BroadcastUpdatePlugin
if (canRegisterSW) {
  navigator.serviceWorker.addEventListener('message', async (event) => {
    // Optional: ensure the message came from workbox-broadcast-update
    if (event.data.meta === 'workbox-broadcast-update') {
      const { cacheName, updatedURL } = event.data.payload;

      const cache = await caches.open(cacheName);
      const updatedResponse = await cache.match(updatedURL);
      const updatedUser = await updatedResponse.json();

      if (!updatedUser?.data?.user) return;

      store.commit('auth/setUser', updatedUser.data.user);
      store.commit('auth/setPinnedWatchlists', updatedUser.data.pinnedWatchlists);
      await onLogin(apolloClients.default);
    }
  });

  getBroadcastChannel().onmessage = async (event) => {
    if (event.data && event.data.type === 'RELOAD_USER') {
      await store.dispatch('auth/getUser');
    }
  };
}

const requireLogin = () => new Promise((resolve, reject) => {
  if (store.getters['auth/isLoggedIn']) {
    resolve();
    return;
  }
  eventBus.$once('auth:didSignIn', resolve);
  eventBus.$emit('auth:signIn', 'to continue');

  setTimeout(() => {
    reject(new Error('Authentication attempt timed out'));
  }, 60 * 1000);
});

function requirePremium(action = null) {
  console.log('requirePremium', store.getters['auth/isLoggedIn'], store.getters['auth/isPremium']);
  if (store.getters['auth/isPremium']) {
    return;
  }

  eventBus.$emit('auth:showPremiumModal', action);
  throw new Error('Premium access required');
}

NProgress.start();

router.beforeEach((to, from, next) => {
  NProgress.start();
  next();
});
router.afterEach(() => {
  nextTick(() => {
    NProgress.done();
  });
});

// eslint-disable-next-line no-undef
window.chargebee = Chargebee.init({
  site: process.env.VUE_APP_CHARGEBEE_SITE,
  publishableKey: process.env.VUE_APP_CHARGEBEE_KEY,
});

setupRealTime({ store });

Promise.all([
  store.dispatch('auth/init'),
  store.dispatch('settings/init'),
  store.dispatch('gas/init'),
]).then(([user]) => {
  if (!!window.SharedWorker && user) {
    //
    let key = localStorage.getItem('aiwuik');
    if (!key) {
      key = window.crypto.randomUUID();
      localStorage.setItem('aiwuik', key);
    }
    initRemoteSigningStorage(key);
    initRemoteSigning(
      get('automation-imported-wallets', {}),
    ).then((keysServed) => {
      store.commit('auth/setRemoteSigningKeysServed', keysServed || new Set([]));
    });
  }

  router.beforeEach(async (to, from, nextRoute) => {
    if (!to.meta?.freeAllowed) {
      await requireLogin();
      await requirePremium();
    }

    nextRoute();
  });
});

router.beforeEach((to, from, nextRoute) => {
  if (!Array.isArray(to.meta?.requiresFeature) && to.meta?.requiresFeature && !store.getters['auth/isFeatureFlagEnabled'](to.meta.requiresFeature)) {
    NProgress.done();
    return;
  }

  if (Array.isArray(to.meta?.requiresFeature) && to.meta?.requiresFeature && !to.meta?.requiresFeature.every((item) => store.getters['auth/isFeatureFlagEnabled'](item))) {
    NProgress.done();
    return;
  }

  nextRoute();
});
const app = createApp({
  setup() {
    provide(ApolloClients, {
      ...apolloClients,
    });

    provide('requireLogin', requireLogin);
  },
  mounted() {
    NProgress.done();
  },

  render: () => h(App),
});

app
  .use(cronLight)
  .use(store)
  .use(router)
  .use(metaManager)
  .use(metaPlugin)
  .use(Vue3TouchEvents)
  .use(VueGridLayout)
  .use(VueGtag, {
    config: {
      id: 'G-YPY1XS3VTN',
      params: {
        send_page_view: false,
      },
    },
  }, router);

app.directive('tooltip', tooltip);
app.directive('scroll-to', VueScrollTo);
app.directive('autofocus', autofocus);

app.mount('#app');
