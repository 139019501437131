import useDialogs from '@/components/ui/composition/use-dialogs';
import { AUTOMATION_FIELD_FRAGMENT } from '@/graphql/automation/fragments/AutomationFieldsFragment';
import { PUBLIC_TEMPLATE_FIELDS_FRAGMENT } from '@/graphql/automation/fragments/PublicTemplateFieldsFragment';
import { useApolloClient, useMutation } from '@vue/apollo-composable';
import gql from 'graphql-tag';
import { cloneDeep } from 'lodash-es';

const createAutomationFromPublicTemplateMutation = gql`
mutation CreateAutomationFromTemplate(
  $templateId: Int!,
  $name: String!,
  $variables: JSON!,
  $status: Boolean
  $folderId: Int
) {
  createAutomationFromPublicTemplate(
    templateId: $templateId,
    name: $name,
    variables: $variables,
    status: $status,
    folderId: $folderId
  ) {
    ...AutomationFields
  }
}
${AUTOMATION_FIELD_FRAGMENT}
`;

export default function usePublicTemplates() {
  const { notify } = useDialogs();
  const { resolveClient } = useApolloClient();
  const client = resolveClient();
  const { mutate: toggleTemplateStar } = useMutation(gql`
    mutation ToggleTemplateStar($id: Int!, $isStarred: Boolean!) {
      toggleTemplateStar(id: $id, isStarred: $isStarred) {
        id
        isStarred
        starCount
      }
    }
  `, {
    errorPolicy: 'none',
    optimisticResponse: ({ id, isStarred }) => {
      const data = client.readFragment({
        id: `PublicTemplate:${id}`,
        fragment: PUBLIC_TEMPLATE_FIELDS_FRAGMENT,
      });
      const starCount = isStarred ? data.starCount + 1 : data.starCount - 1;
      return {
        toggleTemplateStar: {
          __typename: 'PublicTemplate',
          id,
          isStarred,
          starCount,
        },
      };
    },
    update(cache, { data: { toggleTemplateStar: { isStarred, starCount } } }, { variables: { id } }) {
      const data = cache.readFragment({
        id: `PublicTemplate:${id}`,
        fragment: PUBLIC_TEMPLATE_FIELDS_FRAGMENT,
      });
      const newData = cloneDeep(data);
      if (newData.isStarred !== isStarred) {
        newData.isStarred = isStarred;
      }

      if ((starCount || starCount === 0) && newData.starCount !== starCount) {
        newData.starCount = starCount;
      }

      cache.writeFragment({
        id: `PublicTemplate:${id}`,
        fragment: PUBLIC_TEMPLATE_FIELDS_FRAGMENT,
        data: newData,
      });
    },
  });

  const { mutate: createAutomation, loading: isCreatingAutomation } = useMutation(createAutomationFromPublicTemplateMutation, () => ({
    errorPolicy: 'none',
    updateQueries: {
      GetAutomations: (prev, { mutationResult: { data: { createAutomationFromPublicTemplate } } }) => {
        const data = JSON.parse(JSON.stringify(prev));
        data.automations = [
          createAutomationFromPublicTemplate,
          ...data.automations,
        ];
        return data;
      },
    },
  }));

  return {
    toggleTemplateStar: (variables) => toggleTemplateStar(variables).catch((err) => {
      notify({
        message: 'Failed to star template, try again.',
        variant: 'warning',
      });
      throw err;
    }),
    createAutomation: (...args) => createAutomation(...args).then((res) => {
      notify({
        message: 'Automation created',
      });
      return res.data.createAutomationFromPublicTemplate;
    }).catch((err) => {
      notify({
        message: 'Failed to create Automation, check your inputs and try again.',
        variant: 'warning',
      });
      throw err;
    }),
    isCreatingAutomation,
  };
}
