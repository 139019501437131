const routesNames = {
  automation: 'Automation',
  automationExplore: 'Automation.Explore',
  automationCredits: 'Automation.Credits',
  automationExploreTemplatesShow: 'Automation.Explore.Templates.Show',
  automationManage: 'Automation.Manage',
  automationTemplates: 'Automation.Template',
  automationTemplateCreate: 'Automation.Template.Create',
  automationTemplateUpdate: 'Automation.Template.Update',
  automationCreate: 'Automation.Create',
  automationShow: 'Automation.Show',
  automationActionCreate: 'Automation.Action.Create',
  automationActionUpdate: 'Automation.Action.Update',
  automationWallets: 'Automation.Wallets',
  automationActions: 'Automation.Actions',
  automationAlerts: 'Automation.Alerts',
  automationChannels: 'Automation.Channels',
  collectionShow: 'Collection.Show',
  collectionTrade: 'Collection.Trade',
  collectionAnalytics: 'Collection.Analytics',
  collectionMinting: 'Collection.Minting',
  collectionHolders: 'Collection.Holders',
  collectionSocialMetrics: 'Collection.SocialMetrics',
  collectionTraits: 'Collection.Traits',
  collectionItems: 'Collection.Items',
  collectionTransactions: 'Collection.Transactions',
  collectionLeaderboard: 'Collection.Leaderboard',
  wallets: 'Wallets',
  wallet: {
    show: 'Wallet.Show',
    analytics: 'Wallet.Analytics',
    flips: 'Wallet.Flips',
    portfolio: 'Wallet.Portfolio',
    items: 'Wallet.Items',
    realizedGains: 'Wallet.RealizedGains',
    transactions: 'Wallet.Transactions',
    activity: 'Wallet.Activity',
  },
  walletWatchlist: {
    show: 'WalletWatchlist.Show',
    analytics: 'WalletWatchlist.Analytics',
    flips: 'WalletWatchlist.Flips',
    portfolio: 'WalletWatchlist.Portfolio',
    items: 'WalletWatchlist.Items',
    realizedGains: 'WalletWatchlist.RealizedGains',
    transactions: 'WalletWatchlist.Transactions',
    activity: 'WalletWatchlist.Activity',
  },
  portfolio: {
    show: 'Portfolio.Show',
    analytics: 'Portfolio.Analytics',
    flips: 'Portfolio.Flips',
    portfolio: 'Portfolio.Portfolio',
    items: 'Portfolio.Items',
    realizedGains: 'Portfolio.RealizedGains',
    transactions: 'Portfolio.Transactions',
    activity: 'Portfolio.Activity',
  },
  collections: 'Collections',
  trendingCollections: 'Collections.Trending',
  mintingCollections: 'Collections.Minting',
  ownCollections: 'Collections.Own',
  topCollections: 'Collections.Top',
  collectionWatchlists: 'CollectionsWatchlist',
  collectionWatchlistsShow: 'CollectionsWatchlist.Show',
  pulse: 'Pulse',
  pulseOverview: 'Pulse.Overview',
  pulseLeaderboard: 'Pulse.Leaderboard',
  alerts: 'Alerts',
  feed: 'Feed',
  accountUpgrade: 'Account.Upgrade',
};

export default routesNames;
