import { computed } from 'vue';
import { useQuery, useMutation } from '@vue/apollo-composable';
import userChannelsQuery from '@/graphql/channel/queries/userChannels.query.gql';
import deleteAlertChannelMutation from '@/graphql/channel/mutations/deleteAlertChannel.mutation.gql';
import createChannelMutation from '@/graphql/channel/mutations/createChannel.mutation.gql';
import testChannelMutation from '@/graphql/channel/mutations/testChannel.mutation.gql';

export function useUserChannelsGraphql({ queryEnabled = true } = {}) {
  const {
    result,
    onResult,
  } = useQuery(
    userChannelsQuery,
    null,
    () => ({
      enabled: queryEnabled,
    }),
  );
  const channels = computed(() => result.value?.channels || []);

  const { mutate: deleteAlertChannel } = useMutation(
    deleteAlertChannelMutation,
    () => ({
      refetchQueries: [
        {
          query: userChannelsQuery,
        },
      ],
    }),
  );

  const { mutate: createChannel } = useMutation(
    createChannelMutation,
    () => ({
      refetchQueries: [
        {
          query: userChannelsQuery,
        },
      ],
    }),
  );

  const { mutate: testChannel } = useMutation(
    testChannelMutation,
    () => ({
      refetchQueries: [
        {
          query: userChannelsQuery,
        },
      ],
      errorPolicy: 'none',
    }),
  );

  return {
    channels,
    deleteAlertChannel,
    onResult,
    createChannel,
    testChannel,
  };
}
