const STORAGE_KEY = 'compass_app_settings';

export default function () {
  return {
    namespaced: true,
    state: () => ({
      settings: {},
    }),
    actions: {
      init({ commit }) {
        // load from local storage
        const settings = JSON.parse(localStorage.getItem(STORAGE_KEY) ?? '{}');

        commit('setSettings', settings);
      },
      setValue({ commit, state }, { key, value }) {
        commit('setValue', { key, value });
        localStorage.setItem(STORAGE_KEY, JSON.stringify(state.settings));
      },
    },
    mutations: {
      setSettings(state, settings) {
        state.settings = settings;
      },
      setValue(state, { key, value }) {
        state.settings[key] = value;
      },
    },
    getters: {
      getValue(state) {
        return (key) => state.settings[key];
      },
    },
  };
}
