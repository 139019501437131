import CategoryFilterVue from '@/components/filters/CategoryFilter.vue';
import NumberFilterVue from '@/components/filters/NumberFilter.vue';
import CollectionFilterVue from '@/components/filters/CollectionFilter.vue';
import WalletFilterVue from '@/components/filters/WalletFilter.vue';
import TimestampFilterVue from '@/components/filters/TimestampFilter.vue';
import BooleanFilterVue from '@/components/filters/BooleanFilter.vue';
import AttributeFilter from '@/components/filters/AttributeFilter.vue';
import IntervalFilterVue from '@/components/filters/IntervalFilter.vue';
import ArrayFilterVue from '@/components/filters/ArrayFilter.vue';
import NumericTraitFilterVue from '@/components/filters/NumericTraitFilter.vue';

export const FILTER_TYPES = {
  NUMBER: 'number',
  CATEGORY: 'category',
  TIMESTAMP: 'timestamp',
  COLLECTION: 'collection',
  WALLET: 'wallet',
  BOOLEAN: 'boolean',
  LOGICAL: 'logical',
  INTERVAL: 'interval',
  ARRAY: 'array',
  NUMERIC_TRAIT: 'numericTrait',
};

export const FILTER_COMPONENTS = {
  [FILTER_TYPES.NUMBER]: NumberFilterVue,
  [FILTER_TYPES.CATEGORY]: CategoryFilterVue,
  [FILTER_TYPES.COLLECTION]: CollectionFilterVue,
  [FILTER_TYPES.WALLET]: WalletFilterVue,
  [FILTER_TYPES.TIMESTAMP]: TimestampFilterVue,
  [FILTER_TYPES.BOOLEAN]: BooleanFilterVue,
  [FILTER_TYPES.INTERVAL]: IntervalFilterVue,
  [FILTER_TYPES.ARRAY]: ArrayFilterVue,
  [FILTER_TYPES.NUMERIC_TRAIT]: NumericTraitFilterVue,
};

export const FILTER_ICONS = {
  [FILTER_TYPES.NUMBER]: 'fas fa-greater-than-equal',
  [FILTER_TYPES.CATEGORY]: 'fas fa-tag',
  [FILTER_TYPES.COLLECTION]: 'fas fa-tag',
  [FILTER_TYPES.WALLET]: 'fas fa-wallet',
  [FILTER_TYPES.TIMESTAMP]: 'fas fa-calendar',
  [FILTER_TYPES.BOOLEAN]: 'fas fa-check',
  [FILTER_TYPES.INTERVAL]: 'fas fa-clock',
  [FILTER_TYPES.ARRAY]: 'fas fa-list',
  [FILTER_TYPES.NUMERIC_TRAIT]: 'fas fa-greater-than-equal',
};

export const FILTERS = {
  ACTIVITY: [
    {
      key: 'type',
      name: 'Type',
      type: FILTER_TYPES.CATEGORY,
      defaultValue: ['mint', 'sale', 'list'],
      props: {
        multiple: true,
        options: [
          {
            name: 'Mint',
            value: 'mint',
          },
          {
            name: 'Burn',
            value: 'burn',
          },
          {
            name: 'Transfer',
            value: 'transfer',
          },
          {
            name: 'Sale',
            value: 'sale',
          },
          {
            name: 'List',
            value: 'list',
          },
          {
            name: 'Bid',
            value: 'bid',
          },
        ],
      },
    },
    {
      key: 'collectionId',
      name: 'Collection',
      type: FILTER_TYPES.COLLECTION,
      autoPin: true,
      defaultValue: {
        comparator: 'in',
        value: [],
      },
    },
    {
      key: 'tokenId',
      name: 'Token ID',
      type: FILTER_TYPES.NUMBER,
      defaultValue: {
        comparator: 'eq',
        value: null,
      },
    },
    {
      key: 'fromAddress',
      name: 'From',
      type: FILTER_TYPES.WALLET,
      defaultValue: {
        comparator: 'in',
        value: [],
      },
    },
    {
      key: 'toAddress',
      name: 'To',
      type: FILTER_TYPES.WALLET,
      defaultValue: {
        comparator: 'in',
        value: [],
      },
    },
    {
      key: 'value',
      name: 'Price',
      type: FILTER_TYPES.NUMBER,
    },
    {
      key: 'timestamp',
      name: 'Timestamp',
      type: FILTER_TYPES.TIMESTAMP,
    },
    {
      key: '$or',
      name: 'Or',
      type: FILTER_TYPES.LOGICAL,
      defaultValue: [],
    },
  ],
  COLLECTION_TOKENS: [
    {
      key: 'ownerId',
      type: FILTER_TYPES.WALLET,
      name: 'Owner',
    },
    {
      key: 'price',
      type: FILTER_TYPES.NUMBER,
      name: 'Price',
    },
    {
      key: 'lastPrice',
      type: FILTER_TYPES.NUMBER,
      name: 'Last Price',
    },
    {
      key: 'tokenId',
      type: FILTER_TYPES.NUMBER,
      name: 'Token ID',
    },
    {
      key: 'rarityRank',
      type: FILTER_TYPES.NUMBER,
      name: 'Rarity Rank',
    },
    {
      key: 'rarityRankPercentage',
      type: FILTER_TYPES.NUMBER,
      name: 'Rarity Rank (%)',
    },
    {
      key: 'isFlagged',
      type: FILTER_TYPES.BOOLEAN,
      name: 'Is Flagged',
      inline: true,
    },
    {
      key: 'listStatus',
      type: FILTER_TYPES.CATEGORY,
      name: 'List Status',
      props: {
        multiple: false,
        options: [
          {
            name: 'Listed',
            value: 'listed',
          },
          {
            name: 'Unlisted',
            value: 'unlisted',
          },
        ],
      },
    },
    {
      key: 'receivedAt',
      type: FILTER_TYPES.TIMESTAMP,
      name: 'Received',
    },
  ],
  WALLET_COLLECTIONS: [
    {
      name: 'Liquidity',
      key: 'liquidity',
      type: FILTER_TYPES.CATEGORY,
      defaultValue: ['Low', 'Medium', 'High'],
      props: {
        multiple: true,
        options: [
          {
            name: 'Dead',
            value: 'Dead',
          },
          {
            name: 'Low',
            value: 'Low',
          },
          {
            name: 'Medium',
            value: 'Medium',
          },
          {
            name: 'High',
            value: 'High',
          },
        ],
      },
    },
    {
      name: 'Token Count',
      key: 'tokenCount',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Collection',
      key: 'collectionId',
      type: FILTER_TYPES.COLLECTION,
      autoPin: true,
    },
    {
      name: 'Floor Price',
      key: 'floorPrice',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Avg. Invested',
      key: 'avgInvested',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Estimated Value',
      key: 'estimatedValue',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Revenue',
      key: 'totalReceived',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Invested',
      key: 'totalInvested',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Gas Spent',
      key: 'totalGasSpent',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Tokens Received',
      key: 'tokensIn',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Tokens Sent',
      key: 'tokensOut',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Balance',
      key: 'balance',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Estimated value',
      key: 'estimatedValue',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Tokens sold',
      key: 'tokensSold',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Realized gains',
      key: 'realizedGains',
      type: FILTER_TYPES.NUMBER,
    },
    {
      name: 'Unrealized gains',
      key: 'unrealizedGains',
      type: FILTER_TYPES.NUMBER,
    },
    {
      key: 'receivedAt',
      type: FILTER_TYPES.TIMESTAMP,
      name: 'Tokens Received',
    },
  ],
  WALLET_TOKENS: [
    {
      key: 'hideSpam',
      type: FILTER_TYPES.BOOLEAN,
      name: 'Hide spam',
      inline: true,
      defaultValue: true,
    },
    {
      name: 'Liquidity',
      key: 'liquidity',
      type: FILTER_TYPES.CATEGORY,
      defaultValue: ['Low', 'Medium', 'High'],
      props: {
        multiple: true,
        options: [
          {
            name: 'Dead',
            value: 'Dead',
          },
          {
            name: 'Low',
            value: 'Low',
          },
          {
            name: 'Medium',
            value: 'Medium',
          },
          {
            name: 'High',
            value: 'High',
          },
        ],
      },
    },
    {
      key: 'collectionId',
      type: FILTER_TYPES.COLLECTION,
      name: 'Collection',
    },
    {
      key: 'price',
      type: FILTER_TYPES.NUMBER,
      name: 'Price',
    },
    {
      key: 'lastPrice',
      type: FILTER_TYPES.NUMBER,
      name: 'Price',
    },
    {
      key: 'listStatus',
      type: FILTER_TYPES.CATEGORY,
      name: 'List Status',
      props: {
        multiple: false,
        options: [
          {
            name: 'Listed',
            value: 'listed',
          },
          {
            name: 'Unlisted',
            value: 'unlisted',
          },
        ],
      },
    },
    {
      key: 'receivedAt',
      type: FILTER_TYPES.TIMESTAMP,
      name: 'Received',
    },
  ],
};
