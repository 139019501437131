import useDialogs from '@/components/ui/composition/use-dialogs';
import { random } from 'lodash-es';

// eslint-disable-next-line import/no-mutable-exports
let worker;
const requestMap = new Map();
const { notify } = useDialogs();

const request = (type, payload) => {
  let requestId;
  do {
    requestId = random(1, 100000000000);
  } while (requestMap.has(requestId));

  const promise = new Promise((resolve, reject) => {
    worker.port.postMessage({
      type,
      payload,
      requestId,
    });
    requestMap.set(requestId, { resolve, reject });
  });

  return promise;
};

// fetch wallets from storage and start serving them
const init = (data) => {
  if (!worker) {
    worker = new SharedWorker(`${process.env.BASE_URL}remote-signing-worker.js`, {
      name: 'remote-signing',
    });

    worker.port.onmessage = (e) => {
      const { requestId, type, payload } = e.data;
      if (!requestId && type === 'notify') {
        notify(payload);
        return;
      }
      if (requestId && !requestMap.has(requestId)) {
        return;
      }

      const requestPromise = requestMap.get(requestId);
      const { resolve, reject } = requestPromise;
      requestMap.delete(requestId);

      if (payload.error) {
        reject(new Error(payload.error));
      } else {
        resolve(payload);
      }
    };
  }
  return request('init', data);
};

const reset = () => request('reset');

// restart socket
const restart = () => request('restart');

const addKey = (key) => request('addKey', key);

export {
  worker, init, restart, request, addKey, reset,
};
