import { computed, ref, watch } from 'vue';

const count = ref(0);
const hasAnyModals = computed(() => count.value > 0);
watch(() => hasAnyModals.value, (value) => {
  const element = document.querySelector('body');
  if (value) {
    element.style.overflow = 'hidden';
  } else {
    element.style.overflow = 'auto';
  }
});
const modalHidden = () => count.value--;
const modalShown = () => count.value++;

export function useGlobalModal() {
  return {
    hasAnyModals,
    count,
    modalShown,
    modalHidden,
  };
}
