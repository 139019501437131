export function displayName(input) {
  if (!input) {
    return '';
  }

  return input.toLowerCase().split(' ').map((item) => {
    if (item === 'id') {
      return 'ID';
    }

    if (!item.length) {
      return item;
    }

    return `${item[0].toUpperCase()}${item.substr(1)}`;
  }).join(' ');
}
